import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "./GlobalNavMenu.css";

export default function GlobalNavMenu({ text, subMenu, onClickSubMenu }) {

  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const clickOutside = event => {
    if (!ref.current || ref.current.contains(event.target)) {
      return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("click", clickOutside);
    return () => {
      document.removeEventListener("click", clickOutside);
    };
  }, []);

  return (
    <div className="globalnav-menu">
      <p
        ref={ref}
        onClick={() => setIsOpen(!isOpen)}
      >{text}</p>
      { isOpen &&
        <ul className="globalnav-sub-menu">
          {subMenu.map(({ text, path }, index) => (
            <li key={index}><Link to={path} onClick={onClickSubMenu}>{text}</Link></li>
          ))}
        </ul>
      }
    </div>
  );
};
