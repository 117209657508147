import { useState, useEffect } from "react";
import "./MaximizingPhotosyntheticBarChart.css";

export default function MaximizingPhotosyntheticBarChart({ realPhotosyntheticRate, changedPhotosyntheticRate }) {

  const [increment, setIncrement] = useState(null);
  const [decrement, setDecrement] = useState(null);

  const displayValue = (value, fractionDigits) => {
    if (value === null) return "N/A";
    if (typeof fractionDigits === "number") return Number.parseFloat(value).toFixed(fractionDigits);
    return value;
  }

  useEffect(() => {

    const diff = (0 < realPhotosyntheticRate
      ? -(1 - changedPhotosyntheticRate / realPhotosyntheticRate)
      : (1 - changedPhotosyntheticRate / realPhotosyntheticRate)) * 100;

    if (diff === 0) return;

    if (0 < diff) {
      setIncrement(diff);
      setDecrement(null);
    } else {
      setIncrement(null);
      setDecrement(diff);
    }

  }, [realPhotosyntheticRate, changedPhotosyntheticRate]);

  return (
    <div className="maximizing-photosynthetic-barchart-container">
      <div className="">0%</div>
      <div className="maximizing-photosynthetic-barchart-chart-area">
        <div className="maximizing-photosynthetic-barchart-decrease-bar-area">
          <div>{ Number.isFinite(decrement) ? `${displayValue(decrement, 2)}%` : ""}</div>
          <div
            className="maximizing-photosynthetic-barchart-decrease-bar"
            style={{ width: Number.isFinite(decrement) ? `${-(displayValue(decrement, 2) * 2)}px` : 0 }}
          />
        </div>
        <div className="maximizing-photosynthetic-barchart-vertical-bar"></div>
        <div className="maximizing-photosynthetic-barchart-increase-bar-area">
          <div
            className="maximizing-photosynthetic-barchart-increase-bar"
            style={{ width: Number.isFinite(increment) ? `${displayValue(increment, 2) * 2}px` : 0 }}
          />
          <div>{ Number.isFinite(increment) ? `+${displayValue(increment, 2)}%` : ""}</div>
        </div>
      </div>
    </div>
  );
}
