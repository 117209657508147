import { useState, useEffect, useCallback } from "react";
import { addDays, subDays, startOfDay, endOfDay, set } from "date-fns";
import client from "../api/client";

const SELECT_OPTIONS = [ "max", "mean", "min", "sum" ];
const TIME_SLOT_OPTIONS = [ "daytime", "nighttime" ];

const getTimeSlot = (infoName, latestTimestamp, daysAgo, timeSlot) => {
  const baseDate = subDays(latestTimestamp, daysAgo);
  let startDate = startOfDay(baseDate);
  let endDate = endOfDay(baseDate);
  if (timeSlot === TIME_SLOT_OPTIONS[0]) {
    if (infoName === "co2_concentration") {
      startDate = set(baseDate, { hours: 9, minutes: 0, seconds: 0, milliseconds: 0 });
      endDate = set(baseDate, { hours: 15, minutes: 59, seconds: 59, milliseconds: 999 });
    } else {
      startDate = set(baseDate, { hours: 7, minutes: 0, seconds: 0, milliseconds: 0 });
      endDate = set(baseDate, { hours: 17, minutes: 59, seconds: 59, milliseconds: 999 });
    }
  } else if (timeSlot === TIME_SLOT_OPTIONS[1]) {
    startDate = set(baseDate, { hours: 18, minutes: 0, seconds: 0, milliseconds: 0 });
    endDate = set(addDays(baseDate, 1), { hours: 6, minutes: 59, seconds: 59, milliseconds: 999 });
  }
  return [startDate, endDate];
};

export default function useDailyReportEnvInfo(infoName, houseName, latestTimestamp, daysAgo, select, timeSlot = undefined, polling = false) {

  const [timestamp, setTimestamp] = useState(null);
  const [value, setValue] = useState(null);

  const handleSetValue = useCallback(() => {
    const [startDate, endDate] = getTimeSlot(infoName, latestTimestamp, daysAgo, timeSlot);
    const params = {
      house_name: houseName,
      start: startDate.toISOString(),
      end: endDate.toISOString(),
      select
    };
    client.get(`/env/${infoName}`, { params })
      .then(response => {
        if (response.data[0]) {
          const data = response.data[0];
          setTimestamp(new Date(data.timestamp));
          setValue(data.value);
        }
      })
      .catch(error => console.log(error));
  }, [ infoName, houseName, latestTimestamp, daysAgo, select, timeSlot ]);

  useEffect(() => {
    if (!latestTimestamp || !latestTimestamp instanceof Date) return;
    if (daysAgo < 0) return;
    if (!SELECT_OPTIONS.includes(select)) return;
    if (timeSlot && !TIME_SLOT_OPTIONS.includes(timeSlot)) return;
    handleSetValue();
    if (!polling) return;
    const pollingInterval = 1000 * Number.parseInt(process.env.REACT_APP_POLLING_INTERVAL);
    const interval = setInterval(handleSetValue, pollingInterval);
    return () => {
      clearInterval(interval);
      setTimestamp(null);
      setValue(null);
    };
  }, [ handleSetValue, latestTimestamp, daysAgo, select, timeSlot, polling ]);

  return { timestamp, value };
};
