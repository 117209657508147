import { useState, useEffect, useCallback } from "react";
import { subDays, startOfDay, endOfDay } from "date-fns";
import client from "../api/client";

const SELECT_OPTIONS = [ "max", "mean", "min", "sum" ];

export default function useDailyReportSP2Info(infoName, houseName, latestTimestamp, daysAgo, select, polling = false) {

  const [timestamp, setTimestamp] = useState(null);
  const [value, setValue] = useState(null);

  const handleSetValue = useCallback(() => {
    const baseDate = subDays(latestTimestamp, daysAgo);
    const startDate = startOfDay(baseDate);
    const endDate = endOfDay(baseDate);
    const params = {
      house_name: houseName,
      start: startDate.toISOString(),
      end: endDate.toISOString(),
      select
    };
    client.get(`/sp2/db/${infoName}`, { params })
      .then(response => {
        if (response.data[0]) {
          const data = response.data[0];
          setTimestamp(new Date(data.timestamp));
          setValue(data.value);
        }
      })
      .catch(error => console.log(error));
  }, [ infoName, houseName, latestTimestamp, daysAgo, select ]);

  useEffect(() => {
    if (!latestTimestamp || !latestTimestamp instanceof Date) return;
    if (daysAgo < 0) return;
    if (!SELECT_OPTIONS.includes(select)) return;
    handleSetValue();
    if (!polling) return;
    const pollingInterval = 1000 * Number.parseInt(process.env.REACT_APP_POLLING_INTERVAL);
    const interval = setInterval(handleSetValue, pollingInterval);
    return () => {
      clearInterval(interval);
      setTimestamp(null);
      setValue(null);
    };
  }, [ handleSetValue, latestTimestamp, daysAgo, select, polling ]);

  return { timestamp, value };
};
