import { useEffect, useState } from "react";
import { format, getHours } from "date-fns";
import spatialDistributionImageApi from "../../api/spatialDistributionImageApi";
import Slider from "react-slick";
import DailySpatialDistributionImageSliderImage from "./DailySpatialDistributionImageSliderImage";
import DailySpatialDistributionImageSliderThumbnail from "./DailySpatialDistributionImageSliderThumbnail"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./DailySpatialDistributionImageSlider.css";

export default function DailySpatialDistributionImageSlider({
  imageType,
  houseName,
  cameraName,
  cameraDisplayName,
  date
}) {

  const [searchDate, setSearchDate] = useState(date);
  const [timestamps, setTimestamps] = useState([]);
  const [mainNav, setMainNav] = useState(null);
  const [thumbNav, setThumbNav] = useState(null);

  useEffect(() => {

    if (!houseName || !cameraName) return;

    (async () => {
      const timestamps = await spatialDistributionImageApi.fetchTimestampsOfDay(imageType, houseName, cameraName, searchDate);
      setTimestamps(timestamps);
    })();

  }, [imageType, houseName, cameraName, searchDate]);;

  useEffect(() => {
    let index = 0;
    if (0 < timestamps.length) {
      const noonIndex = timestamps.findIndex(timestamp => getHours(timestamp) === 12);
      index = 0 <= noonIndex ? noonIndex : timestamps.length - 1;
    }
    if (mainNav && mainNav.innerSlider) {
      mainNav.slickGoTo(index, true);
    }
  }, [timestamps, mainNav])

  const mainSliderSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const thumbSliderSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    swipeToSlide: true,
    focusOnSelect: true,
  };

  return (
    <div className="daily-spatial-distribution-image-slider-container">
      <div className="daily-spatial-distribution-image-slider-title">{cameraDisplayName}</div>
      <div className="daily-spatial-distribution-image-slider-input-area">
        <label>
          取得日：
          <input
            type="date"
            value={format(searchDate, "yyyy-MM-dd")}
            onChange={event => setSearchDate(new Date(event.target.value))}
          />
        </label>
      </div>
      <div className="daily-spatial-distribution-image-slider-content">
        <div className="daily-spatial-distribution-image-slider-slider-area">
          <div className="daily-spatial-distribution-image-main-slider">
            {0 < timestamps.length &&
              <Slider
                asNavFor={thumbNav}
                ref={mainSlider => setMainNav(mainSlider)}
                {...mainSliderSettings}
              >
                {timestamps.map((timestamp, i) => (
                  <div key={i}>
                    <DailySpatialDistributionImageSliderImage
                      imageType={imageType}
                      houseName={houseName}
                      cameraName={cameraName}
                      timestamp={timestamp}
                    />
                    <div className="daily-spatial-distribution-image-slider-slide-timestamp">{format(timestamp, "yyyy/MM/dd HH:mm:ss")}</div>
                  </div>
                ))}
              </Slider>
            }
          </div>
          <div className="daily-spatial-distribution-image-thumb-slider">
            {0 < timestamps.length &&
              <Slider
                asNavFor={mainNav}
                ref={thumbSlider => setThumbNav(thumbSlider)}
                {...thumbSliderSettings}
              >
                {timestamps.map((timestamp, i) => (
                  <div key={i}>
                    <DailySpatialDistributionImageSliderThumbnail
                      imageType={imageType}
                      houseName={houseName}
                      cameraName={cameraName}
                      timestamp={timestamp}
                    />
                    <div className="daily-spatial-distribution-image-slider-slide-timestamp">{format(timestamp, "HH:mm")}</div>
                  </div>
                ))}
              </Slider>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
