import { useParams } from "react-router-dom";
import { useGroup } from "../../hooks/use-group";
import House from "../shared/House";
import CurrentValue from "./CurrentValue";
import DailyReport from "./DailyReport";
import MaximizingPhotosynthetic from "./MaximizingPhotosynthetic";
import IrrigationIndicator from "./IrrigationIndicator";
import HeaterFuelConsumption from "./HeaterFuelConsumption";
import "./Home.css";

export default function Home() {

  const { houseName } = useParams();
  const { group } = useGroup();
  const house = group.houses.find(house => house.name === houseName);
  const {
    name,
    displayName,
    cultivation,
    cameras,
    simulationCo2Concentration,
    simulationAirTemperature,
    arableLandArea,
    displaySetting,
    irrigationSettings,
    heaters
  } = house;
  const irrigationSetting = irrigationSettings[cultivation.crop.name];

  return (
    <>
      <House
        displayName={displayName}
        cropName={cultivation.crop.name}
        cropDisplayName={cultivation.crop.displayName}
        plantingDate={cultivation.plantingDate}
      >
        <div className="home-container">
          <div className="home-content">
            <CurrentValue
              key={"current-value-" + name}
              groupName={group.name}
              houseName={name}
              cameraName={cameras[0].name}
              cropName={cultivation.crop.name}
              plantingDate={cultivation.plantingDate}
              displaySetting={displaySetting}
            />
          </div>
          <div className="home-content">
            <DailyReport
              key={"daily-report-" + name}
              houseName={name}
              houseDisplayName={displayName}
              plantingDate={cultivation.plantingDate}
              arableLandArea={arableLandArea}
              displaySetting={displaySetting}
              heaters={heaters}
            />
          </div>
          <div className="home-content">
            <MaximizingPhotosynthetic
              key={"maximizing-photosynthetic-" + name}
              groupName={group.name}
              houseName={name}
              houseDisplayName={displayName}
              cropName={cultivation.crop.name}
              plantingDate={cultivation.plantingDate}
              simulationCo2Concentration={simulationCo2Concentration}
              simulationAirTemperature={simulationAirTemperature}
            />
          </div>
          <div className="home-content">
            <IrrigationIndicator
              key={"irrigation-indicator-" + name}
              groupName={group.name}
              houseName={name}
              houseDisplayName={displayName}
              cropName={cultivation.crop.name}
              plantingDate={cultivation.plantingDate}
              arableLandArea={arableLandArea}
              idealIrrigationCoefficient={irrigationSetting && irrigationSetting.idealIrrigationCoefficient}
              plantingDensity={irrigationSetting && irrigationSetting.plantingDensity}
              solarIrradianceProportionalWaterAmount={irrigationSetting && irrigationSetting.solarIrradianceProportionalWaterAmount}
            />
          </div>
          { heaters && 0 < heaters.length && (
            <div className="home-content">
              <HeaterFuelConsumption
                key={"heater-fuel-consumption-" + name}
                houseName={name}
                heaters={heaters}
                plantingDate={cultivation.plantingDate}
              />
            </div>
          )}
        </div>
      </House>
    </>
  );
}
