import { useParams } from "react-router-dom";
import { useGroup } from "../../hooks/use-group";

import House from "../shared/House";
import MonthlySpatialDistributionImageCalendar from "./MonthlySpatialDistributionImageCalendar";

export default function SpatialDistributionImage() {

  const { group } = useGroup();
  const { houseName } = useParams();

  const house = group.houses.find(house => house.name === houseName);
  const camera = house.cameras[0];
  const crop = house.cultivation.crop;

  return (
    <>
      <House
        displayName={house.displayName}
        cropName={crop.name}
        cropDisplayName={crop.displayName}
        plantingDate={house.cultivation.plantingDate}
      >
        <MonthlySpatialDistributionImageCalendar
          houseName={houseName}
          cameraName={camera && camera.name}
          cameraDisplayName={camera && camera.displayName}
          key={houseName}
        />
      </House>
    </>
  );
}
