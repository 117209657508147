import SP2InfoListItem from "./SP2InfoListItem";
import "./SP2InfoList.css";

const INFO_DATA = {
  "eggplant": [
    "lai",
    "photosynthetic_rate",
    "fruit_load",
    "transpiration_rate",
    "number_of_blooms",
    "leaf_temperature",
  ],
  "chinese-chive": [
    "lai",
    "photosynthetic_rate",
    "fresh_weight",
    "transpiration_rate",
    "leaf_length",
    "leaf_temperature",
  ],
  "bell-pepper": [
    "lai",
    "photosynthetic_rate",
    "transpiration_rate",
    "leaf_temperature",
  ],
  "tomato": [
    "lai",
    "photosynthetic_rate",
    "transpiration_rate",
    "leaf_temperature",
  ],
  "melon": [
    "lai",
    "photosynthetic_rate",
    "fruit_load",
    "transpiration_rate",
    "number_of_blooms",
    "leaf_temperature",
  ],
  "grape": [
    "lai",
    "photosynthetic_rate",
    "fruit_load",
    "transpiration_rate",
    "number_of_blooms",
    "leaf_temperature",
  ],
  "spinach": [
    "lai",
    "photosynthetic_rate",
    "fresh_weight",
    "transpiration_rate",
    "leaf_length",
    "leaf_temperature",
  ],
  "wasabi": [
    "lai",
    "photosynthetic_rate",
    "fresh_weight",
    "transpiration_rate",
    "leaf_length",
    "leaf_temperature",
  ],
  "strawberry": [
    "lai",
    "photosynthetic_rate",
    "fruit_load",
    "transpiration_rate",
    "number_of_blooms",
    "leaf_temperature",
  ],
  "cucumber": [
    "lai",
    "photosynthetic_rate",
    "fruit_load",
    "transpiration_rate",
    "number_of_blooms",
    "leaf_temperature",
  ],
  "green-pepper": [
    "lai",
    "photosynthetic_rate",
    "transpiration_rate",
    "leaf_temperature",
  ],
  "green-pepper-s": [
    "lai",
    "photosynthetic_rate",
    "transpiration_rate",
    "leaf_temperature",
  ],
  "corn": [
    "lai",
    "photosynthetic_rate",
    "fruit_load",
    "transpiration_rate",
    "number_of_blooms",
    "leaf_temperature",
  ],
  "mandarin-orange": [
    "lai",
    "photosynthetic_rate",
    "fruit_load",
    "transpiration_rate",
    "number_of_blooms",
    "leaf_temperature",
  ],
};

export default function SP2InfoList({ groupName, houseName, cameraName, cropName, plantingDate, displaySetting }) {

  const infoListItems = INFO_DATA[cropName].map((infoName, i) => (
    <SP2InfoListItem
      key={houseName + "-" + infoName}
      groupName={groupName}
      houseName={houseName}
      cameraName={cameraName}
      infoName={infoName}
      cropName={cropName}
      plantingDate={plantingDate}
      displaySetting={displaySetting}
    />
  ));

  return (
    <ul className="sp2-info-list">
      {infoListItems}
    </ul>
  );
};
