import client from './client';

const login = async (userName, password) => {
  try {
    await client.get('/set-csrf');
    const response = await client.post('/login', {
      user_name: userName,
      password
    });
    return { userName: response.data.user_name };
  } catch (error) {
    throw error;
  }
}

const logout = async () => {
  try {
    await client.get('/logout');
  } catch (error) {
    throw error;
  }
}

const authApi = {
  login,
  logout,
};

export default authApi;