import { useState, useContext, createContext } from "react";
import groupApi from "../api/groupApi";
import viewHouseApi from "../api/viewHouseApi";

const groupContext = createContext();

const compareHouses = (a, b) => {
  if (a.displayOrder === null && b.displayOrder === null) {
    return 0;
  }
  if (a.displayOrder === null) {
    return 1;
  }
  if (b.displayOrder === null) {
    return -1;
  }
  return a.displayOrder - b.displayOrder;
};

export const GroupProvider = ({ children }) => {

  const group = useGroupProvider();

  return (
    <groupContext.Provider value={group}>
      {children}
    </groupContext.Provider>
  );
};

export const useGroup = () => useContext(groupContext);

const useGroupProvider = () => {

  const [group, setGroup] = useState(null);

  const initGroup = async () => {

    const groups = await groupApi.fetchAll();
    const _group = groups[0];

    const houses = await viewHouseApi.fetchAll(_group.name);
    for (const house of houses) {
      house.cultivation = house.cultivations[0]
    }

    houses.sort(compareHouses);

    setGroup({ ..._group, houses });
  };

  const clearGroup = () => {
    setGroup(null);
  }

  return { group, initGroup, clearGroup };
};
