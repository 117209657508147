import { useEffect, useState } from "react";
import spatialDistributionThumbnailApi from "../../api/spatialDistributionThumbnailApi";

export default function DailySpatialDistributionImageSliderThumbnail({ imageType, houseName, cameraName, timestamp }) {

  const [image, setImage] = useState(null);

  useEffect(() => {
    if (!houseName || !cameraName || !timestamp) return;
    (async () => {
      const imageResponse = await spatialDistributionThumbnailApi.fetch(
        imageType,
        houseName,
        cameraName,
        timestamp.toISOString(),
        timestamp.toISOString(),
      );
      setImage(imageResponse[0]);
    })();
    return () => setImage(null)
  }, [ imageType, houseName, cameraName, timestamp ]);;

  return (
    <div>
      { image && <img src={`data:image/${image.fileType};base64,${image.value}`} width="100%" alt={image.timestamp} /> }
    </div>
  );
}
