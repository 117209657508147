import client from "./client";

const update = async (groupName, data) => {
  try {
    await client.patch(`/groups/${groupName}/house_display_orders`, data);
  } catch (error) {
    throw error;
  }
};

const houseDisplayOrderApi = {
  update
};

export default houseDisplayOrderApi;
