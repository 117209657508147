import { useState } from "react";
import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";

import { AuthProvider } from "../hooks/use-auth";
import { GroupProvider } from "../hooks/use-group";

import Header from "./Header";
import GlobalNav from "./GlobalNav";
import PrivateRoute from "./PrivateRoute";
import Login from "./login/Login";
import Home from "./home/Home";
import Detail from "./detail/Detail";
import CameraImage from "./camera-image/CameraImage";
import SpatialDistributionImage from "./spatial-distribution-image/SpatialDistributionImage";
import HouseDisplayOrder from "./house-display-order/HouseDisplayOrder";

import "./App.css";

function App() {

  const [showGlobalNav, setShowGlobalNav] = useState(false);

  const toggleGlobalNav = () => {
    setShowGlobalNav(!showGlobalNav);
  };

  const hideGlobalNav = () => {
    setShowGlobalNav(false);
  }

  return (
    <AuthProvider>
      <GroupProvider>
        <BrowserRouter>
          <Header
            showGlobalNav={showGlobalNav}
            onClickGlobalNavButton={toggleGlobalNav}
            onClickLogoutButton={hideGlobalNav}
          />
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <PrivateRoute path="/house-display-order">
              <div className="presentation">
                <div className={showGlobalNav ? "globalnav-area active" : "globalnav-area"}>
                  <GlobalNav onClickSubMenu={hideGlobalNav} />
                </div>
                <div className="content-area">
                  <HouseDisplayOrder />
                </div>
              </div>
            </PrivateRoute>
            <PrivateRoute path="/camera-image/:houseName">
              <div className="presentation">
                <div className={showGlobalNav ? "globalnav-area active" : "globalnav-area"}>
                  <GlobalNav onClickSubMenu={hideGlobalNav} />
                </div>
                <div className="content-area">
                  <CameraImage />
                </div>
              </div>
            </PrivateRoute>
            <PrivateRoute path="/spatial-distribution-image/:houseName">
              <div className="presentation">
                <div className={showGlobalNav ? "globalnav-area active" : "globalnav-area"}>
                  <GlobalNav onClickSubMenu={hideGlobalNav} />
                </div>
                <div className="content-area">
                  <SpatialDistributionImage />
                </div>
              </div>
            </PrivateRoute>
            <PrivateRoute path="/detail/:houseName">
              <div className="presentation">
                <div className={showGlobalNav ? "globalnav-area active" : "globalnav-area"}>
                  <GlobalNav onClickSubMenu={hideGlobalNav} />
                </div>
                <div className="content-area">
                  <Detail />
                </div>
              </div>
            </PrivateRoute>
            <PrivateRoute path="/:houseName">
              <div className="presentation">
                <div className={showGlobalNav ? "globalnav-area active" : "globalnav-area"}>
                  <GlobalNav onClickSubMenu={hideGlobalNav} />
                </div>
                <div className="content-area">
                  <Home />
                </div>
              </div>
            </PrivateRoute>
          </Switch>
        </BrowserRouter>
      </GroupProvider>
    </AuthProvider>
  );
}

export default App;
