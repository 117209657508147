import { useState, useContext, createContext } from 'react';
import authApi from '../api/authApi';

const authContext = createContext();

export const AuthProvider = ({ children }) => {
  const auth = useAuthProvider();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
};

export const useAuth = () => useContext(authContext);

const useAuthProvider = () => {

  const [user, setUser] = useState(null);

  const login = async (userName, password) => {
    try {
      const response = await authApi.login(userName, password);
      if (response && response.userName) {
        setUser({ userName: response.userName });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const logout = async () => {
    try {
      await authApi.logout();
      setUser(null);
    } catch (error) {
      console.log(error);
    }
  };

  return { user, login, logout };
};