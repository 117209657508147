import CurrentValueEnvInfoListItem from "./CurrentValueEnvInfoListItem";

export default function CurrentValueEnvInfoList({ houseName, plantingDate }) {

  const envInfoList = [
    { infoName: "air_temperature", displayName: "温度", unit: "℃" },
    { infoName: "relative_humidity", displayName: "湿度", unit: "%RH" },
    { infoName: "solar_irradiance", displayName: "日射量", unit: "W/m2" },
    { infoName: "co2_concentration", displayName: "CO2濃度", unit: "ppm" },
  ];

  const envInfoListItems = envInfoList.map((envInfo, i) => (
    <li key={i}>
      <CurrentValueEnvInfoListItem
        key={houseName + "-" + envInfo.infoName}
        houseName={houseName}
        plantingDate={plantingDate}
        {...envInfo}
      />
    </li>
  ));

  return (
    <ul>
      {envInfoListItems}
    </ul>
  );
};
