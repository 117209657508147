import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';
import { useGroup } from '../hooks/use-group';
import "./Header.css";

export default function Header({ showGlobalNav, onClickGlobalNavButton, onClickLogoutButton }) {

  const history = useHistory();
  const { user, logout } = useAuth();
  const { clearGroup } = useGroup();
  const [showMenu, setShowMenu] = useState(false);

  const menuButtonRef = useRef();
  const menuRef = useRef();

  const clickOutside = event => {
    if (menuButtonRef.current && menuButtonRef.current.contains(event.target)) return;
    if (!menuRef.current) return;
    if (menuRef.current.contains(event.target)) return;
    setShowMenu(false);
  };

  useEffect(() => {
    document.addEventListener("click", clickOutside);
    return () => {
      document.removeEventListener("click", clickOutside);
    };
  }, []);

  useEffect(() => {
    if (!user) {
      clearGroup();
      history.push("/login");
    }
  }, [user, clearGroup, history]);

  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleLogout = () => {
    logout();
    setShowMenu(false);
    onClickLogoutButton();
  };

  const handleClickMenu = (path) => {
    setShowMenu(false);
    history.push(path);
  };

  return (
    <header className="header">
      <div className="header-content">
        {user && (
          <div className={showGlobalNav ? "globalnav-button active" : "globalnav-button"} onClick={onClickGlobalNavButton}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        )}
        <h1>高知大学 IoP実証システム</h1>
        {user && (
          <div ref={menuButtonRef} className="header-menu-button" onClick={handleShowMenu}>
            <p className="user-name-label">{user.userName} ▼</p>
          </div>
        )}
        {showMenu && (
          <ul ref={menuRef} className="header-menu">
            <li>
              <button
                className="logout-button"
                type="button"
                onClick={() => handleClickMenu("/house-display-order")}>圃場表示順</button>
            </li>
            <li>
              <button className="logout-button" type="button" onClick={handleLogout}>ログアウト</button>
            </li>
          </ul>
        )}
      </div>
    </header>
  );
}
