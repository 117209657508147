import { useState, useEffect } from "react";
import { format, startOfDay } from "date-fns";
import ReactModal from "react-modal";
import MaximizingPhotosyntheticSettingModal from "./MaximizingPhotosyntheticSettingModal";
import MaximizingPhotosyntheticCO2ConcentrationChange from "./MaximizingPhotosyntheticCO2ConcentrationChange";
import MaximizingPhotosyntheticTemperatureChange from "./MaximizingPhotosyntheticTemperatureChange";
import client from "../../api/client";

import useLatestEnvInfo from "../../hooks/use-latest-env-info";

import "./MaximizingPhotosynthetic.css";

ReactModal.setAppElement('#root');

const getModalStyle = () => {
  if (window.matchMedia("(max-width: 1024px)").matches) {
    return {
      content: {
        width: "auto",
        height: "fit-content",
        margin: 0
      }
    };
  }
  return {
    content: {
      width: "50%",
      height: "fit-content",
      margin: "auto"
    }
  };
};

export default function MaximizingPhotosynthetic({
  groupName,
  houseName,
  houseDisplayName,
  cropName,
  plantingDate,
  simulationCo2Concentration,
  simulationAirTemperature
}) {

  const [realPhotosyntheticRate, setRealPhotosyntheticRate] = useState(null);
  const [photosyntheticRateAtChangingCO2, setPhotosyntheticRateAtChangingCO2] = useState(null);
  const [photosyntheticRateAtChangingTemp, setPhotosyntheticRateAtChangingTemp] = useState(null);

  const { value: latestCo2Concentration } = useLatestEnvInfo("co2_concentration", houseName, plantingDate);
  const { value: latestAirTemperature } = useLatestEnvInfo("air_temperature", houseName, plantingDate);

  const { timestamp: latestTimestamp } = useLatestEnvInfo("air_temperature", houseName, plantingDate);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!latestTimestamp) return;
    client.get("/sp2/photosynthetic_rate", {
      params: {
        group_name: groupName,
        house_name: houseName,
        crop_name: cropName,
        start: startOfDay(latestTimestamp).toISOString(),
        select: "latest"
      }
    }).then(response => {
      if (response.data[0]) {
        setRealPhotosyntheticRate(response.data[0].value);
      }
    })
    .catch(error => console.log(error));
    return () => setRealPhotosyntheticRate(null);
  }, [ groupName, houseName, cropName, latestTimestamp ]);

  useEffect(() => {
    if (!latestTimestamp) return;
    client.get("/sp2/photosynthetic_rate", {
      params: {
        group_name: groupName,
        house_name: houseName,
        crop_name: cropName,
        start: startOfDay(latestTimestamp).toISOString(),
        select: "latest",
        co2: simulationCo2Concentration
      }
    }).then(response => {
      if (response.data[0]) {
        setPhotosyntheticRateAtChangingCO2(response.data[0].value);
      }
    })
    .catch(error => console.log(error));
    return () => setPhotosyntheticRateAtChangingCO2(null);
  }, [ groupName, houseName, cropName, latestTimestamp, simulationCo2Concentration ]);

  useEffect(() => {
    if (!latestTimestamp) return;
    client.get("/sp2/photosynthetic_rate", {
      params: {
        group_name: groupName,
        house_name: houseName,
        crop_name: cropName,
        start: startOfDay(latestTimestamp).toISOString(),
        select: "latest",
        temp: simulationAirTemperature
      }
    }).then(response => {
      if (response.data[0]) {
        setPhotosyntheticRateAtChangingTemp(response.data[0].value);
      }
    })
    .catch(error => console.log(error));
    return () => setPhotosyntheticRateAtChangingTemp(null);
  }, [ groupName, houseName, cropName, latestTimestamp, simulationAirTemperature ]);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <div className="maximizing-photosynthetic-container">
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        shouldCloseOnOverlayClick={true}
        style={getModalStyle()}
      >
        <MaximizingPhotosyntheticSettingModal
          houseName={houseName}
          houseDisplayName={houseDisplayName}
          simulationCo2Concentration={simulationCo2Concentration}
          simulationAirTemperature={simulationAirTemperature}
          onClose={handleCloseModal}
        />
      </ReactModal>
      <div className="maximizing-photosynthetic-title">
        <div className="maximizing-photosynthetic-title-text">光合成最大化</div>
        { latestTimestamp && <span className="maximizing-photosynthetic-timestamp">{`${format(latestTimestamp, "yyyy/MM/dd HH:mm")} 時点`}</span> }
      </div>
      <div className="maximizing-photosyntheticy-setting-button">
        <button onClick={handleOpenModal}>CO2濃度・温度の設定</button>
      </div>
      <div className="maximizing-photosynthetic-content">
        <MaximizingPhotosyntheticCO2ConcentrationChange
          latestCo2Concentration={latestCo2Concentration}
          latestAirTemperature={latestAirTemperature}
          changedPhotosyntheticRate={photosyntheticRateAtChangingCO2}
          realPhotosyntheticRate={realPhotosyntheticRate}
          simulationCo2Concentration={simulationCo2Concentration}
        />
      </div>
      <div className="maximizing-photosynthetic-content">
        <MaximizingPhotosyntheticTemperatureChange
          latestCo2Concentration={latestCo2Concentration}
          latestAirTemperature={latestAirTemperature}
          changedPhotosyntheticRate={photosyntheticRateAtChangingTemp}
          realPhotosyntheticRate={realPhotosyntheticRate}
          simulationAirTemperature={simulationAirTemperature}
        />
      </div>
    </div>
  );
}
