import { format } from "date-fns";
import "./House.css";
import icon_eggplant from "../../assets/header_icon_eggplant.png";
import icon_chinese_chive from "../../assets/header_icon_chinese_chive.png";
import icon_bell_pepper from "../../assets/header_icon_bell_pepper.png";
import icon_tomato from "../../assets/header_icon_tomato.png";
import icon_melon from "../../assets/header_icon_melon.png";
import icon_grape from "../../assets/header_icon_grape.png";
import icon_spinach from "../../assets/header_icon_spinach.png";
import icon_wasabi from "../../assets/header_icon_wasabi.png";
import icon_strawberry from "../../assets/header_icon_strawberry.png";
import icon_cucumber from "../../assets/header_icon_cucumber.png";
import icon_green_pepper from "../../assets/header_icon_green_pepper.png";
import icon_green_pepper_s from "../../assets/header_icon_green_pepper_s.png";
import icon_corn from "../../assets/header_icon_corn.png";
import icon_mandarin_orange from "../../assets/header_icon_mandarin_orange.png";

const ICONS = {
  "eggplant": icon_eggplant,
  "chinese-chive": icon_chinese_chive,
  "bell-pepper": icon_bell_pepper,
  "tomato": icon_tomato,
  "melon": icon_melon,
  "grape": icon_grape,
  "spinach": icon_spinach,
  "wasabi": icon_wasabi,
  "strawberry": icon_strawberry,
  "cucumber": icon_cucumber,
  "green-pepper": icon_green_pepper,
  "green-pepper-s": icon_green_pepper_s,
  "corn": icon_corn,
  "mandarin-orange": icon_mandarin_orange,
}

export default function House({ displayName, cropName, cropDisplayName, children, plantingDate }) {

  return (
    <div className="house-container">
      <header className={`house-header house-header-${cropName}`}>
        <div className="house-title">
          <div className="house-title-icon">
            <img src={ICONS[cropName]} alt={cropDisplayName} width="100%" />
          </div>
          <div>
            {`${cropDisplayName} (${displayName})`}
          </div>
        </div>
        <div className="house-time">
          {"定植日: " + format(plantingDate, "yyyy/MM/dd")}
        </div>
      </header>
      <div className="house-content">
        {children}
      </div>
    </div>
  );
}
