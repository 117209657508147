import { useState, useEffect, useCallback } from "react";
import { startOfDay, endOfDay } from "date-fns";
import client from "../api/client";

/*
 * 蒸散速度は単位がmmol/㎡/s。
 * 1㎡にある植物が、1秒間に何mmolの水を放出したかで表さる。
 * 1molの水は18mlで1mmolはその1000分の1である。
 * 例えば5mmol/㎡/sである状態が1時間続くと、
 * 1時間での蒸散量は約324ml/㎡=324L/10aのとなる。
 * 1Lの水は1kgなので324L/10a=0.324t/10aである。
 * これが分かると、かん水量の目安として活用できると思われる。
*/
const convertRateToAmountOfTranspirationPer10a = transHourlyMeanValue => {
  if (typeof transHourlyMeanValue !== "number") return null;
  const secondPerHour = 60 * 60;
  const mlPerMilliMol = 18 / 1000;
  return transHourlyMeanValue * secondPerHour * mlPerMilliMol / 1000;
}

export default function useHourlyTranspirationPerDay(houseName, latestTimestamp, polling = false) {

  const [data, setData] = useState(null);

  const handleSetValue = useCallback(() => {
    const startDate = startOfDay(latestTimestamp);
    const endDate = endOfDay(latestTimestamp);
    const params = {
      house_name: houseName,
      start: startDate.toISOString(),
      end: endDate.toISOString(),
      sampling_step: "H",
      sampling_interval: 1,
    };
    client.get("/sp2/db/transpiration_rate", { params })
      .then(response => {
        setData(response.data.map(data => ({ timestamp: data.timestamp, value: convertRateToAmountOfTranspirationPer10a(data.value) })));
      })
      .catch(error => console.log(error));
  }, [ houseName, latestTimestamp ]);

  useEffect(() => {
    if (!latestTimestamp || !latestTimestamp instanceof Date) return;
    handleSetValue();
    if (!polling) return;
    const pollingInterval = 1000 * Number.parseInt(process.env.REACT_APP_POLLING_INTERVAL);
    const interval = setInterval(handleSetValue, pollingInterval);
    return () => {
      clearInterval(interval);
      setData(null);
    };
  }, [ handleSetValue, latestTimestamp, polling ]);

  return { data };
};
