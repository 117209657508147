import { useState } from "react";
import {
  format,
  eachWeekOfInterval,
  eachDayOfInterval,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  isSameMonth,
} from "date-fns";
import MonthlyImageCalendarThumbnail from "./MonthlyImageCalendarThumbnail";
import DailyImageSlider from "./DailyImageSlider";
import ReactModal from "react-modal";
import DatePicker from 'react-datepicker'
import ja from 'date-fns/locale/ja';

import 'react-datepicker/dist/react-datepicker.css'
import "./MonthlyImageCalendar.css";

ReactModal.setAppElement('#root');

function getCalendarArray(baseDate) {
  const sundays = eachWeekOfInterval({
    start: startOfMonth(baseDate),
    end: endOfMonth(baseDate)
  });
  return sundays.map(sunday =>
    eachDayOfInterval({start: sunday, end: endOfWeek(sunday)})
  );
}

export default function MonthlyImageCalendar({ houseName, cameras }) {

  const [selectedCamera, setSelectedCamera] = useState(cameras[0]);
  const [searchMonth, setSearchMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [calendarArray, setCalendarArray] = useState(getCalendarArray(new Date()))
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (date) => {
    setSelectedDate(date);
    setIsModalOpen(true);
  }

  const handleCloseModal = () => setIsModalOpen(false);

  const cameraOptions = cameras.map(({ name, displayName }) => <option key={name} value={name}>{displayName}</option>);

  const handleSelectCamera = event => {
    const selectedCamera = cameras.find(camera => camera.name === event.target.value);
    setSelectedCamera(selectedCamera);
  };

  const handleChangeSearchMonth = date => {
    setSearchMonth(date);
    setCalendarArray(getCalendarArray(date));
  };

  const dateElements = calendarArray.flat().map((date, i) => {
    if (isSameMonth(date, searchMonth)) {
      return (
        <li key={date.getTime()}>
          <span>{format(date, "d")}</span>
          <div className="monthly-image-calendar-thumbnail-area">
            <MonthlyImageCalendarThumbnail
              houseName={houseName}
              cameraName={selectedCamera.name}
              date={date}
              onClick={handleOpenModal}
            />
          </div>
        </li>
      );
    }
    return <li key={i}></li>;
  });

  return (
    <div className="monthly-image-calendar-container">
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        shouldCloseOnOverlayClick={true}
      >
        <div className="monthly-image-calendar-modal">
          <div
            className="monthly-image-calendar-modal-close-button"
            onClick={handleCloseModal}
          >
            <span>×</span>
          </div>
          <DailyImageSlider
            houseName={houseName}
            cameraName={selectedCamera.name}
            cameraDisplayName={selectedCamera.displayName}
            date={selectedDate}
          />
        </div>
      </ReactModal>
      <div className="monthly-image-calendar-input-area">
        <div className="monthly-image-calendar-input-select-camera">
          <p>カメラ：</p>
          <select value={selectedCamera.name} onChange={handleSelectCamera}>
            {cameraOptions}
          </select>
        </div>
        <div className="monthly-image-calendar-input-search-month">
          <p>対象月：</p>
          <DatePicker
            locale={ja}
            selected={searchMonth}
            onChange={handleChangeSearchMonth}
            dateFormat="yyyy年MM月"
            showMonthYearPicker
            onKeyDown={event => event.preventDefault()}
          />
        </div>
      </div>
      <div className="monthly-image-calendar-content">
        <div className="monthly-image-calendar">
          <ul className="monthly-image-calendar-header">
            <li>日</li>
            <li>月</li>
            <li>火</li>
            <li>水</li>
            <li>木</li>
            <li>金</li>
            <li>土</li>
          </ul>
          <ul className="monthly-image-calendar-dates">
            {dateElements}
          </ul>
        </div>
      </div>
    </div>
  );
}
