import { useParams } from "react-router-dom";
import { useGroup } from "../../hooks/use-group";

import House from "../shared/House";
import MonthlyImageCalendar from "./MonthlyImageCalendar";

export default function CameraImage() {

  const { group } = useGroup();
  const { houseName } = useParams();

  const house = group.houses.find(house => house.name === houseName);
  const cameras = house.cameras;
  const crop = house.cultivation.crop;

  return (
    <>
      <House
        displayName={house.displayName}
        cropName={crop.name}
        cropDisplayName={crop.displayName}
        plantingDate={house.cultivation.plantingDate}
      >
        <MonthlyImageCalendar houseName={houseName} cameras={cameras} key={houseName} />
      </House>
    </>
  );
}
