import client from "./client";

const create = async (houseName, cropName, data) => {
  try {
    await client.post(`/houses/${houseName}/irrigation_settings/${cropName}`, data);
  } catch (error) {
    throw error;
  }
};

const update = async (houseName, cropName, data) => {
  try {
    await client.patch(`/houses/${houseName}/irrigation_settings/${cropName}`, data);
  } catch (error) {
    throw error;
  }
};

const irrigationSettingApi = {
  create,
  update
};

export default irrigationSettingApi;
