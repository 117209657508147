import { useState } from "react";
import { format } from "date-fns";
import ReactModal from "react-modal";
import DailyReportTable from "./DailyReportTable";
import DailyReportDisplaySettingModal from "./DailyReportDisplaySettingModal";
import useLatestEnvInfo from "../../hooks/use-latest-env-info";
import "./DailyReport.css";

ReactModal.setAppElement('#root');

const getModalStyle = () => {
  if (window.matchMedia("(max-width: 1024px)").matches) {
    return {
      content: {
        width: "auto",
        height: "fit-content",
        margin: 0
      }
    };
  }
  return {
    content: {
      width: "50%",
      height: "fit-content",
      margin: "auto"
    }
  };
};

export default function DailyReport({ houseName, houseDisplayName, plantingDate, arableLandArea, displaySetting, heaters }) {

  const { timestamp: latestTimestamp } = useLatestEnvInfo("air_temperature", houseName, plantingDate);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <div className="daily-report-container">
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        shouldCloseOnOverlayClick={true}
        style={getModalStyle()}
      >
        <DailyReportDisplaySettingModal
          houseName={houseName}
          houseDisplayName={houseDisplayName}
          displaySetting={displaySetting}
          onClose={handleCloseModal}
        />
      </ReactModal>
      <div className="daily-report-title">
        <div className="daily-report-title-icon">
        </div>
        <div className="daily-report-title-text">日報</div>
        { latestTimestamp && <span className="daily-report-timestamp">{`${format(latestTimestamp, "yyyy/MM/dd HH:mm")} 時点`}</span> }
      </div>
      <div className="daily-report-display-setting-button">
        <button onClick={handleOpenModal}>表示項目の設定</button>
      </div>
      <div className="daily-report-content">
        <DailyReportTable
          key={houseName}
          houseName={houseName}
          latestTimestamp={latestTimestamp}
          arableLandArea={arableLandArea}
          displaySetting={displaySetting}
          heaters={heaters}
        />
      </div>
    </div>
  );
};
