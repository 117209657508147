import client from "./client";

const fetchAll = async () => {
  try {
    const response = await client.get("/groups");
    return response.data.map(({name, display_name}) => ({ name, displayName: display_name}));
  } catch (error) {
    throw error;
  }
}

const groupApi = {
  fetchAll
};

export default groupApi;