import { useState } from "react";
import { format } from "date-fns";
import useLatestImage from "../../hooks/use-latest-image";
import "./LatestImage.css";

export default function LatestImage({ houseName, cameras, plantingDate }) {

  const [selectedCamera, setSelectedCamera] = useState(cameras[0]);
  const { image } = useLatestImage(houseName, selectedCamera.name, plantingDate);

  const cameraOptions = cameras.map(({ name, displayName }) => <option key={name} value={name}>{displayName}</option>);

  const handleSelectCamera = event => {
    const selectedCamera = cameras.find(camera => camera.name === event.target.value);
    setSelectedCamera(selectedCamera);
  };

  return (
    <div className="latest-image-container">
      <div className="latest-image-title">
        <div className="latest-image-title-text">圃場内カメラ画像</div>
        { image && <span className="latest-image-timestamp">{`${format(new Date(image.timestamp), "yyyy/MM/dd HH:mm")} 時点`}</span> }
      </div>
      <div className="latest-image-select-camera">
        <select value={selectedCamera.name} onChange={handleSelectCamera}>
          {cameraOptions}
        </select>
      </div>
      <div>
        { image &&
          <img
            src={`data:image/${image.fileType};base64,${image.value}`}
            alt={selectedCamera.displayName} width="100%"
          />
        }
      </div>
    </div>
  );
};
