import { useEffect, useState } from "react";
import thumbnailApi from "../../api/thumbnailApi";

export default function DailyImageSliderThumbnail({ houseName, cameraName, timestamp }) {

  const [image, setImage] = useState(null);

  useEffect(() => {
    if (!houseName || !cameraName || !timestamp) return;
    (async () => {
      const imageResponse = await thumbnailApi.fetch(
        houseName,
        cameraName,
        timestamp.toISOString(),
        timestamp.toISOString(),
      );
      setImage(imageResponse[0]);
    })();
    return () => setImage(null)
  }, [houseName, cameraName, timestamp]);;

  return (
    <div>
      { image && <img src={`data:image/${image.fileType};base64,${image.value}`} width="100%" alt={image.timestamp} /> }
    </div>
  );
}
