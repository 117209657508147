import axios from "axios";

const client = axios.create({
  baseURL: process.env.REACT_APP_WEB_API_BASE_URL,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
  withCredentials: true,
  paramsSerializer: { indexes: null },
});

export default client;
