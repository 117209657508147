import Plot from "react-plotly.js";

const visible = (dividend, divisor) => {
  return 0 < dividend && 0 < divisor && dividend <= divisor;
};

export default function MaximizingPhotosyntheticPlot({ dividend, divisor }) {

  const plotData = [{
    sort: false,
    values: [ dividend, divisor - dividend ],
    type: "pie",
    marker: {
      colors: ["rgb(76, 195, 186)", "rgba(0, 0, 0, 0)"]
    },
    textfont: {
      size: [24, 12],
      color: ["white", "white"]
    },
    hole: 0.5,
  }];

  const layout = {
    autosize: true,
    hovermode: false,
    showlegend: false,
  };

  return (
    <>
    { visible(dividend, divisor) &&
      <Plot
        data={plotData}
        style={{ width: "100%", height: "100%" }}
        layout={layout}
        useResizeHandler={true}
      />
    }
    </>
  );
}
