import { useState, useEffect, useCallback } from "react";
import imageApi from "../api/imageApi";

export default function useLatestImage(houseName, cameraName, startDate = undefined) {

  const [image, setImage] = useState(null);

  const handleSetImage = useCallback(() => {

    const start = startDate && startDate.toISOString();

    imageApi.fetch(houseName, cameraName, start, undefined, "latest")
      .then(response => {
        if (!response || !response.length) return;
        setImage(response[0]);
      })
      .catch(error => console.log(error));

  }, [ houseName, cameraName, startDate ]);

  useEffect(() => {
    handleSetImage();
    const pollingInterval = 1000 * Number.parseInt(process.env.REACT_APP_POLLING_INTERVAL);
    const interval = setInterval(handleSetImage, pollingInterval);
    return () => {
      clearInterval(interval);
      setImage(null);
    };
  }, [ handleSetImage ]);

  return { image };
};
