import { useState } from "react";
import { useGroup } from '../../hooks/use-group';
import houseApi from "../../api/houseApi";

import "./MaximizingPhotosyntheticSettingModal.css";

const MIN_CO2_CONCENTRATION = 1.0;
const MAX_CO2_CONCENTRATION = 9999.0;

const MIN_AIR_TEMPERATURE = 1.0;
const MAX_AIR_TEMPERATURE = 99.0;

export default function MaximizingPhotosyntheticSettingModal({
  houseName,
  houseDisplayName,
  simulationCo2Concentration,
  simulationAirTemperature,
  onClose
}) {

  const [co2Concentration, setCo2Concentration] = useState(simulationCo2Concentration);
  const [airTemperature, setAirTemperature] = useState(simulationAirTemperature);
  const { initGroup } = useGroup();

  const handleChangeCo2Concentration = (value) => {
    if (value === "") return setCo2Concentration("");
    if (isNaN(value)) return setCo2Concentration("");
    if (value >= MIN_CO2_CONCENTRATION && value <= MAX_CO2_CONCENTRATION) {
      setCo2Concentration(value);
    }
  };

  const handleChangeAirTemperatur = (value) => {
    if (value === "") return setAirTemperature("");
    if (isNaN(value)) return setAirTemperature("");
    if (value >= MIN_AIR_TEMPERATURE && value <= MAX_AIR_TEMPERATURE) {
      setAirTemperature(value);
    }
  };

  const handleUpdateSetting = async () => {
    try {
      await houseApi.update(houseName, {
        simulation_co2_concentration: co2Concentration,
        simulation_air_temperature: airTemperature,
      });
      await initGroup();
    } catch (error) {
      console.log(error);
    }
    onClose();
  };

  return (
    <div className="maximizing-photosynthetic-setting-modal-container">
      <div className="maximizing-photosynthetic-setting-modal-title">
        <p className="maximizing-photosynthetic-setting-modal-title-text">CO2濃度・温度の設定（{houseDisplayName}）</p>
        <div
          className="maximizing-photosynthetic-setting-modal-close-button"
          onClick={onClose}
        >
          <span>×</span>
        </div>
      </div>
      <div className="maximizing-photosynthetic-setting-modal-table">
        <table>
          <thead>
            <tr>
              <th>項目名</th>
              <th>変更値</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>CO2濃度</td>
              <td>
                <input
                  type="text"
                  value={co2Concentration}
                  onChange={(event) => handleChangeCo2Concentration(event.target.value)}
                /> ppm
              </td>
            </tr>
            <tr>
              <td>温度</td>
              <td>
                <input
                  type="text"
                  value={airTemperature}
                  onChange={(event) => handleChangeAirTemperatur(event.target.value)}
                /> ℃
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="maximizing-photosynthetic-setting-modal-update-button">
        <button onClick={handleUpdateSetting}>更新</button>
      </div>
    </div>
  );
};
