import { useEffect, useState } from "react";
import { setMilliseconds } from "date-fns";
import imageApi from "../../api/imageApi";
import spatialDistributionImageApi from "../../api/spatialDistributionImageApi";

import "./DailySpatialDistributionImageSliderImage.css";

export default function DailySpatialDistributionImageSliderImage({ imageType, houseName, cameraName, timestamp }) {

  const [image, setImage] = useState(null);
  const [spatialDistributionImage, setSpatialDistributionImage] = useState(null);

  useEffect(() => {
    if (!houseName || !cameraName || !timestamp) return;
    (async () => {
      const imageResponse = await imageApi.fetch(
        houseName, 
        cameraName, 
        timestamp.toISOString(),
        // AIエンジンが分布画像のタイムスタンプとしてカメラ画像のタイムスタンプからミリ秒を削除した値を使用しているので
        // 分布画像とカメラ画像のタイムスタンプの差異を吸収するため
        setMilliseconds(timestamp, 999).toISOString(),
      );
      setImage(imageResponse[0]);
      const spatialDistributionImageResponse = await spatialDistributionImageApi.fetch(
        imageType,
        houseName,
        cameraName,
        timestamp.toISOString(),
        timestamp.toISOString(),
      );
      setSpatialDistributionImage(spatialDistributionImageResponse[0]);
    })();
    return () => {
      setImage(null);
      setSpatialDistributionImage(null);
    };
  }, [ imageType, houseName, cameraName, timestamp ]);;

  return (
    <div className="daily-spatial-distribution-image-slider-image-container">
      <div>
        { image && (
          <img 
            src={`data:image/${image.fileType};base64,${image.value}`} 
            width="100%" 
            alt={image.timestamp} /> 
        )}
      </div>
      <div>
        { spatialDistributionImage && (
          <img 
            src={`data:image/${spatialDistributionImage.fileType};base64,${spatialDistributionImage.value}`} 
            width="100%" 
            alt={spatialDistributionImage.timestamp} /> 
        )}
      </div>
    </div>
  );
}
