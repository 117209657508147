import EnvInfoListItem from "./EnvInfoListItem";

export default function EnvInfoList({ houseName, envData }) {

  const envInfoList = [
    { infoName: "air_temperature", displayName: "温度", unit: "℃" },
    { infoName: "relative_humidity", displayName: "湿度", unit: "%RH" },
    { infoName: "co2_concentration", displayName: "CO2濃度", unit: "ppm" },
    { infoName: "solar_irradiance", displayName: "日射量", unit: "W/m2" },
    { infoName: "vapor_pressure_deficit", displayName: "飽差", unit: "g/m3" },
    { infoName: "soil_volumetric_water_content", displayName: "土壌水分", unit: "%" },
    { infoName: "soil_electrical_conductivity", displayName: "土壌電気伝導度", unit: "dS/m" },
    { infoName: "soil_temperature", displayName: "土壌温度", unit: "℃" },
  ];

  const envInfoListItems = envInfoList.map((envInfo, i) => (
    <li key={i}>
      <EnvInfoListItem
        key={houseName + "-" + envInfo.infoName}
        envData={envData}
        {...envInfo}
      />
    </li>
  ));

  return (
    <ul>
      {envInfoListItems}
    </ul>
  );
};
