import { set, startOfDay, endOfDay } from "date-fns";
import client from "./client";

const fetch = async (houseName, cameraName, start = undefined, end = undefined, select = undefined, noValue = false) => {
  try {
    const response = await client.get("/thumbnails", {
      params: {
        house_name: houseName,
        camera_name: cameraName,
        start,
        end,
        select,
        no_value: noValue
      }
    });
    return response.data.map(({ house_name, camera_name, timestamp, file_type, value }) => ({
      houseName: house_name,
      cameraName: camera_name,
      timestamp,
      fileType: file_type,
      value
    }));
  } catch (error) {
    throw error;
  }
};

const fetchFirstInNoon = async (houseName, cameraName, date) => {
  try {
    const start = set(date, { hours: 12, minutes: 0, seconds: 0, milliseconds: 0 }).toISOString();
    const end = set(date, { hours: 12, minutes: 59, seconds: 59, milliseconds: 999 }).toISOString();
    const response = await fetch(houseName, cameraName, start, end, "oldest");
    if (!response || !response.length) return;
    return response[0];
  } catch (error) {
    throw error;
  }
};

const fetchLatestFrom9To15 = async (houseName, cameraName, date) => {
  try {
    const start = set(date, { hours: 9, minutes: 0, seconds: 0, milliseconds: 0 }).toISOString();
    const end = set(date, { hours: 15, minutes: 59, seconds: 59, milliseconds: 999 }).toISOString();
    const response = await fetch(houseName, cameraName, start, end, "latest");
    if (!response || !response.length) return;
    return response[0];
  } catch (error) {
    throw error;
  }
}

const fetchLatestInDay = async (houseName, cameraName, date) => {
  try {
    const start = startOfDay(date).toISOString();
    const end = endOfDay(date).toISOString();
    const response = await fetch(houseName, cameraName, start, end, "latest");
    if (!response || !response.length) return;
    return response[0];
  } catch (error) {
    throw error;
  }
}

const thumbnailApi = {
  fetch,
  fetchFirstInNoon,
  fetchLatestFrom9To15,
  fetchLatestInDay,
};

export default thumbnailApi;
