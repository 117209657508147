import CurrentValueSP2InfoListItem from "./CurrentValueSP2InfoListItem";

const INFO_NAMES = [
  "photosynthetic_rate",
  "transpiration_rate",
  "lai",
  "leaf_temperature",
];

export default function CurrentValueSP2InfoList({ groupName, houseName, cameraName, cropName, plantingDate, displaySetting }) {

  const infoListItems = INFO_NAMES.map(infoName => (
    <CurrentValueSP2InfoListItem
      key={houseName + "-" + infoName}
      groupName={groupName}
      houseName={houseName}
      cameraName={cameraName}
      infoName={infoName}
      cropName={cropName}
      plantingDate={plantingDate}
      displaySetting={displaySetting}
    />
  ));

  return (
    <ul>
      {infoListItems}
    </ul>
  );
};
