import { convertToVaporPressureDeficit } from "../../utils/calcUtil";

import "./EnvInfoListItem.css";
import icon_air_temperature from "../../assets/env_info_icon_air_temperature.png";
import icon_co2_concentration from "../../assets/env_info_icon_co2_concentration.png";
import icon_relative_humidity from "../../assets/env_info_icon_relative_humidity.png";
import icon_solar_irradiance from "../../assets/env_info_icon_solar_irradiance.png";
import icon_vapor_pressure_deficit from "../../assets/env_info_icon_vapor_pressure_deficit.png";
import icon_soil_volumetric_water_content from "../../assets/env_info_icon_soil_volumetric_water_content.png";
import icon_soil_electrical_conductivity from "../../assets/env_info_icon_soil_electrical_conductivity.png";
import icon_soil_temperature from "../../assets/env_info_icon_soil_temperature.png";

const ICONS = {
  air_temperature: icon_air_temperature,
  co2_concentration: icon_co2_concentration,
  relative_humidity: icon_relative_humidity,
  solar_irradiance: icon_solar_irradiance,
  vapor_pressure_deficit: icon_vapor_pressure_deficit,
  soil_volumetric_water_content: icon_soil_volumetric_water_content,
  soil_electrical_conductivity: icon_soil_electrical_conductivity,
  soil_temperature: icon_soil_temperature,
};

const displayValue = (envData, infoName) => {

  if (!envData) return "N/A";

  if (infoName === "vapor_pressure_deficit") {
    const airTemperature = envData["air_temperature"]?.[0]?.value;
    const relativeHumidity = envData["relative_humidity"]?.[0]?.value;
    if (!airTemperature || ! relativeHumidity) return "N/A";
    const value = convertToVaporPressureDeficit(airTemperature, relativeHumidity);
    return Math.round(value * 10) / 10;
  };

  let value = envData[infoName]?.[0]?.value;
  if (!value) return "N/A";

  if (infoName === "solar_irradiance") {
    // レイアウトが崩れるので小数点第一位までしか表示しない。
    value = Math.round(value * 10) / 10;
  }

  return value;
};

export default function EnvInfoListItem({ envData, infoName, displayName, unit }) {
  return (
    <div className="env-info-list-item-container">
      <div className="env-info-list-item-icon">
        <img src={ICONS[infoName]} alt={infoName} width="100%" />
      </div>
      <div className="env-info-list-item-content">
        <div className="env-info-list-item-name">{displayName}</div>
        <div className="env-info-list-item-value">
          {displayValue(envData, infoName)} <span className="env-info-list-item-value-unit">{unit}</span>
        </div>
      </div>
    </div>
  );
};
