import { useState, useEffect, useCallback } from "react";
import client from "../api/client";

export default function useLatestEnvInfo(infoName, houseName, startDate = undefined) {

  const [timestamp, setTimestamp] = useState(null);
  const [value, setValue] = useState(null);

  const handleSetValue = useCallback(() => {
    const params = {
      house_name: houseName,
      start: startDate && startDate.toISOString(),
      select: "latest"
    };
    client.get(`/env/${infoName}`, { params })
      .then(response => {
        if (response.data[0]) {
          const data = response.data[0];
          setTimestamp(new Date(data.timestamp));
          setValue(data.value);
        }
      })
      .catch(error => console.log(error));
  }, [ infoName, houseName, startDate ]);

  useEffect(() => {
    handleSetValue();
    const pollingInterval = 1000 * Number.parseInt(process.env.REACT_APP_POLLING_INTERVAL);
    const interval = setInterval(handleSetValue, pollingInterval);
    return () => {
      clearInterval(interval);
      setTimestamp(null);
      setValue(null);
    };
  }, [ handleSetValue ]);

  return { timestamp, value };
};
