import client from "./client";

const fetchAll = async (groupName) => {
  try {
    const response = await client.get(`/groups/${groupName}/houses`);
    return response.data.map(house => {
      return {
        name: house.name,
        displayName: house.display_name,
        simulationCo2Concentration: house.simulation_co2_concentration,
        simulationAirTemperature: house.simulation_air_temperature,
        arableLandArea: house.arable_land_area,
        displaySetting: house.display_setting,
      };
    });
  } catch (error) {
    throw error;
  }
};

const update = async (houseName, data) => {
  try {
    await client.patch(`/houses/${houseName}`, data);
  } catch (error) {
    throw error;
  }
};

const houseApi = {
  fetchAll,
  update
};

export default houseApi;
