import { useState, useEffect, useCallback } from "react";
import { startOfDay, endOfDay } from "date-fns";
import client from "../api/client";

export default function useHourlyHeaterRunTimePerDay(houseName, latestTimestamp, polling = false) {

  const [data, setData] = useState(null);

  const handleSetValue = useCallback(() => {
    const startDate = startOfDay(latestTimestamp);
    const endDate = endOfDay(latestTimestamp);
    const params = {
      house_name: houseName,
      start: startDate.toISOString(),
      end: endDate.toISOString(),
      select: "sum",
      sampling_step: "H",
      sampling_interval: 1,
    };
    client.get("/env/heater_run_time", { params })
      .then(response => {
        setData(response.data.reduce((accumulator, { timestamp, heater_name, value }) => {
          if (accumulator[timestamp]) {
            accumulator[timestamp][heater_name] = value;
          } else {
            accumulator[timestamp] = { [heater_name]: value };
          }
          return accumulator;
        }, {}));
      })
      .catch(error => console.log(error));
  }, [ houseName, latestTimestamp ]);

  useEffect(() => {
    if (!latestTimestamp || !latestTimestamp instanceof Date) return;
    handleSetValue();
    if (!polling) return;
    const pollingInterval = 1000 * Number.parseInt(process.env.REACT_APP_POLLING_INTERVAL);
    const interval = setInterval(handleSetValue, pollingInterval);
    return () => {
      clearInterval(interval);
      setData(null);
    };
  }, [ handleSetValue, latestTimestamp, polling ]);

  return { data };
};
