import { useState, useEffect } from "react";
import { subDays, startOfDay, endOfDay, eachDayOfInterval, differenceInSeconds } from "date-fns";
import Plot from "react-plotly.js";
import useDailyReportEnvInfo from "../../hooks/use-daily-report-env-info";
import useDailyReportSP2Info from "../../hooks/use-daily-report-sp2-info";
import useDailyReportHeaterRunTime from "../../hooks/use-daily-report-heater-run-time";
import client from "../../api/client";
import { isVisible, useCanopy as _useCanopy } from "../../utils/displaySettingUtil";
import { convertToVaporPressureDeficit } from "../../utils/calcUtil";

import "./DailyReportTable.css";

const getMultipleInfo = async (url, houseName, latestTimestamp, select, targets) => {
  const startDate = startOfDay(subDays(latestTimestamp, 7));
  const endDate = endOfDay(subDays(latestTimestamp, 1));
  const params = {
    house_name: houseName,
    start: startDate.toISOString(),
    end: endDate.toISOString(),
    select,
    targets,
    sampling_step: "D",
    sampling_interval: 1,
  };
  const response = await client.get(url, { params });
  return response.data;
};

const getMultipleEnvInfo = async (houseName, latestTimestamp, select, targets) => {
  return await getMultipleInfo("/env", houseName, latestTimestamp, select, targets);
};

const getDailySetters = (latestTimestamp, setters) => {
  const days = eachDayOfInterval({
    start: startOfDay(subDays(latestTimestamp, 7)),
    end: startOfDay(subDays(latestTimestamp, 1))
  }).map(date => date.toISOString().replace("Z", "+00:00"));
  return days.reduce((result, currentDay, currentIndex) => {
    result[currentDay] = setters[currentIndex];
    return result;
  }, {});
};

const setMultipleInfo = async (houseName, latestTimestamp, select, getter, setters) => {
  const targets = Object.keys(setters);
  const data = await getter(houseName, latestTimestamp, select, targets);
  for (const target of targets) {
    if (!data[target] || data[target].length === 0) continue;
    const dailySetters = getDailySetters(latestTimestamp, setters[target]);
    data[target].forEach(({ timestamp, value }) => {
      dailySetters[timestamp](value);
    });
  }
};

const setMultipleEnvInfo = async (houseName, latestTimestamp, select, setters) => {
  await setMultipleInfo(houseName, latestTimestamp, select, getMultipleEnvInfo, setters);
};

const groupByDate = (data) => {

  const groupedData = {};

  data.forEach(entry => {
      const date = new Date(entry.timestamp);
      const startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0);
      const startDateISOString = startDate.toISOString();

      if (!groupedData[startDateISOString]) {
          groupedData[startDateISOString] = [];
      }

      groupedData[startDateISOString].push(entry);
  });

  return groupedData;
}

const getDailyData = async (url, houseName, latestTimestamp) => {

  const startDate = startOfDay(subDays(latestTimestamp, 7));
  const endDate = endOfDay(subDays(latestTimestamp, 1));
  const params = {
    house_name: houseName,
    start: startDate.toISOString(),
    end: endDate.toISOString()
  };

  let groupedData = null;
  try {
    const { data } = await client.get(url, { params });
    groupedData = groupByDate(data);
  } catch (error) {
    console.log(error);
  }

  return groupedData;
};

const setDailyData = async (url, houseName, latestTimestamp, setters) => {

  const dailyData = await getDailyData(url, houseName, latestTimestamp);

  if (!dailyData) return;

  const days = eachDayOfInterval({
    start: startOfDay(subDays(latestTimestamp, 7)),
    end: startOfDay(subDays(latestTimestamp, 1))
  }).sort((a, b) => a - b);

  setters[0](dailyData[days[0].toISOString()]);
  setters[1](dailyData[days[1].toISOString()]);
  setters[2](dailyData[days[2].toISOString()]);
  setters[3](dailyData[days[3].toISOString()]);
  setters[4](dailyData[days[4].toISOString()]);
  setters[5](dailyData[days[5].toISOString()]);
  setters[6](dailyData[days[6].toISOString()]);
};

export default function DailyReportTable({ houseName, latestTimestamp, arableLandArea, displaySetting, heaters }) {

  const { value: tempDailyMax7dAgoValue } = useDailyReportEnvInfo("air_temperature", houseName, latestTimestamp, 7, "max");
  const { value: tempDailyMax6dAgoValue } = useDailyReportEnvInfo("air_temperature", houseName, latestTimestamp, 6, "max");
  const { value: tempDailyMax5dAgoValue } = useDailyReportEnvInfo("air_temperature", houseName, latestTimestamp, 5, "max");
  const { value: tempDailyMax4dAgoValue } = useDailyReportEnvInfo("air_temperature", houseName, latestTimestamp, 4, "max");
  const { value: tempDailyMax3dAgoValue } = useDailyReportEnvInfo("air_temperature", houseName, latestTimestamp, 3, "max");
  const { value: tempDailyMax2dAgoValue } = useDailyReportEnvInfo("air_temperature", houseName, latestTimestamp, 2, "max");
  const { value: tempDailyMax1dAgoValue } = useDailyReportEnvInfo("air_temperature", houseName, latestTimestamp, 1, "max");

  const [ tempDailyMean7dAgoValue, setTempDailyMean7dAgoValue ] = useState(null);
  const [ tempDailyMean6dAgoValue, setTempDailyMean6dAgoValue ] = useState(null);
  const [ tempDailyMean5dAgoValue, setTempDailyMean5dAgoValue ] = useState(null);
  const [ tempDailyMean4dAgoValue, setTempDailyMean4dAgoValue ] = useState(null);
  const [ tempDailyMean3dAgoValue, setTempDailyMean3dAgoValue ] = useState(null);
  const [ tempDailyMean2dAgoValue, setTempDailyMean2dAgoValue ] = useState(null);
  const [ tempDailyMean1dAgoValue, setTempDailyMean1dAgoValue ] = useState(null);

  const { value: tempDailyMin7dAgoValue } = useDailyReportEnvInfo("air_temperature", houseName, latestTimestamp, 7, "min");
  const { value: tempDailyMin6dAgoValue } = useDailyReportEnvInfo("air_temperature", houseName, latestTimestamp, 6, "min");
  const { value: tempDailyMin5dAgoValue } = useDailyReportEnvInfo("air_temperature", houseName, latestTimestamp, 5, "min");
  const { value: tempDailyMin4dAgoValue } = useDailyReportEnvInfo("air_temperature", houseName, latestTimestamp, 4, "min");
  const { value: tempDailyMin3dAgoValue } = useDailyReportEnvInfo("air_temperature", houseName, latestTimestamp, 3, "min");
  const { value: tempDailyMin2dAgoValue } = useDailyReportEnvInfo("air_temperature", houseName, latestTimestamp, 2, "min");
  const { value: tempDailyMin1dAgoValue } = useDailyReportEnvInfo("air_temperature", houseName, latestTimestamp, 1, "min");

  const { value: tempDaytimeMean7dAgoValue } = useDailyReportEnvInfo("air_temperature", houseName, latestTimestamp, 7, "mean", "daytime");
  const { value: tempDaytimeMean6dAgoValue } = useDailyReportEnvInfo("air_temperature", houseName, latestTimestamp, 6, "mean", "daytime");
  const { value: tempDaytimeMean5dAgoValue } = useDailyReportEnvInfo("air_temperature", houseName, latestTimestamp, 5, "mean", "daytime");
  const { value: tempDaytimeMean4dAgoValue } = useDailyReportEnvInfo("air_temperature", houseName, latestTimestamp, 4, "mean", "daytime");
  const { value: tempDaytimeMean3dAgoValue } = useDailyReportEnvInfo("air_temperature", houseName, latestTimestamp, 3, "mean", "daytime");
  const { value: tempDaytimeMean2dAgoValue } = useDailyReportEnvInfo("air_temperature", houseName, latestTimestamp, 2, "mean", "daytime");
  const { value: tempDaytimeMean1dAgoValue } = useDailyReportEnvInfo("air_temperature", houseName, latestTimestamp, 1, "mean", "daytime");

  const { value: tempNighttimeMean7dAgoValue } = useDailyReportEnvInfo("air_temperature", houseName, latestTimestamp, 7, "mean", "nighttime");
  const { value: tempNighttimeMean6dAgoValue } = useDailyReportEnvInfo("air_temperature", houseName, latestTimestamp, 6, "mean", "nighttime");
  const { value: tempNighttimeMean5dAgoValue } = useDailyReportEnvInfo("air_temperature", houseName, latestTimestamp, 5, "mean", "nighttime");
  const { value: tempNighttimeMean4dAgoValue } = useDailyReportEnvInfo("air_temperature", houseName, latestTimestamp, 4, "mean", "nighttime");
  const { value: tempNighttimeMean3dAgoValue } = useDailyReportEnvInfo("air_temperature", houseName, latestTimestamp, 3, "mean", "nighttime");
  const { value: tempNighttimeMean2dAgoValue } = useDailyReportEnvInfo("air_temperature", houseName, latestTimestamp, 2, "mean", "nighttime");
  const { value: tempNighttimeMean1dAgoValue } = useDailyReportEnvInfo("air_temperature", houseName, latestTimestamp, 1, "mean", "nighttime");

  const [ humDailyMean7dAgoValue, setHumDailyMean7dAgoValue ] = useState(null);
  const [ humDailyMean6dAgoValue, setHumDailyMean6dAgoValue ] = useState(null);
  const [ humDailyMean5dAgoValue, setHumDailyMean5dAgoValue ] = useState(null);
  const [ humDailyMean4dAgoValue, setHumDailyMean4dAgoValue ] = useState(null);
  const [ humDailyMean3dAgoValue, setHumDailyMean3dAgoValue ] = useState(null);
  const [ humDailyMean2dAgoValue, setHumDailyMean2dAgoValue ] = useState(null);
  const [ humDailyMean1dAgoValue, setHumDailyMean1dAgoValue ] = useState(null);

  const { value: co2DaytimeMean7dAgoValue } = useDailyReportEnvInfo("co2_concentration", houseName, latestTimestamp, 7, "mean", "daytime");
  const { value: co2DaytimeMean6dAgoValue } = useDailyReportEnvInfo("co2_concentration", houseName, latestTimestamp, 6, "mean", "daytime");
  const { value: co2DaytimeMean5dAgoValue } = useDailyReportEnvInfo("co2_concentration", houseName, latestTimestamp, 5, "mean", "daytime");
  const { value: co2DaytimeMean4dAgoValue } = useDailyReportEnvInfo("co2_concentration", houseName, latestTimestamp, 4, "mean", "daytime");
  const { value: co2DaytimeMean3dAgoValue } = useDailyReportEnvInfo("co2_concentration", houseName, latestTimestamp, 3, "mean", "daytime");
  const { value: co2DaytimeMean2dAgoValue } = useDailyReportEnvInfo("co2_concentration", houseName, latestTimestamp, 2, "mean", "daytime");
  const { value: co2DaytimeMean1dAgoValue } = useDailyReportEnvInfo("co2_concentration", houseName, latestTimestamp, 1, "mean", "daytime");

  const [ solar7dAgoData, setSolar7dAgoData ] = useState(null);
  const [ solar6dAgoData, setSolar6dAgoData ] = useState(null);
  const [ solar5dAgoData, setSolar5dAgoData ] = useState(null);
  const [ solar4dAgoData, setSolar4dAgoData ] = useState(null);
  const [ solar3dAgoData, setSolar3dAgoData ] = useState(null);
  const [ solar2dAgoData, setSolar2dAgoData ] = useState(null);
  const [ solar1dAgoData, setSolar1dAgoData ] = useState(null);

  const [ trans7dAgoData, setTrans7dAgoData ] = useState(null);
  const [ trans6dAgoData, setTrans6dAgoData ] = useState(null);
  const [ trans5dAgoData, setTrans5dAgoData ] = useState(null);
  const [ trans4dAgoData, setTrans4dAgoData ] = useState(null);
  const [ trans3dAgoData, setTrans3dAgoData ] = useState(null);
  const [ trans2dAgoData, setTrans2dAgoData ] = useState(null);
  const [ trans1dAgoData, setTrans1dAgoData ] = useState(null);

  const { value: laiDailyMax7dAgoValue } = useDailyReportSP2Info("lai", houseName, latestTimestamp, 7, "max");
  const { value: laiDailyMax6dAgoValue } = useDailyReportSP2Info("lai", houseName, latestTimestamp, 6, "max");
  const { value: laiDailyMax5dAgoValue } = useDailyReportSP2Info("lai", houseName, latestTimestamp, 5, "max");
  const { value: laiDailyMax4dAgoValue } = useDailyReportSP2Info("lai", houseName, latestTimestamp, 4, "max");
  const { value: laiDailyMax3dAgoValue } = useDailyReportSP2Info("lai", houseName, latestTimestamp, 3, "max");
  const { value: laiDailyMax2dAgoValue } = useDailyReportSP2Info("lai", houseName, latestTimestamp, 2, "max");
  const { value: laiDailyMax1dAgoValue } = useDailyReportSP2Info("lai", houseName, latestTimestamp, 1, "max");

  const [ photo7dAgoData, setPhoto7dAgoData ] = useState(null);
  const [ photo6dAgoData, setPhoto6dAgoData ] = useState(null);
  const [ photo5dAgoData, setPhoto5dAgoData ] = useState(null);
  const [ photo4dAgoData, setPhoto4dAgoData ] = useState(null);
  const [ photo3dAgoData, setPhoto3dAgoData ] = useState(null);
  const [ photo2dAgoData, setPhoto2dAgoData ] = useState(null);
  const [ photo1dAgoData, setPhoto1dAgoData ] = useState(null);

  const [ irrigationDailySum7dAgoValue, setIrrigationDailySum7dAgoValue ] = useState(null);
  const [ irrigationDailySum6dAgoValue, setIrrigationDailySum6dAgoValue ] = useState(null);
  const [ irrigationDailySum5dAgoValue, setIrrigationDailySum5dAgoValue ] = useState(null);
  const [ irrigationDailySum4dAgoValue, setIrrigationDailySum4dAgoValue ] = useState(null);
  const [ irrigationDailySum3dAgoValue, setIrrigationDailySum3dAgoValue ] = useState(null);
  const [ irrigationDailySum2dAgoValue, setIrrigationDailySum2dAgoValue ] = useState(null);
  const [ irrigationDailySum1dAgoValue, setIrrigationDailySum1dAgoValue ] = useState(null);

  const { data: heaterRunTimeDailySum7dAgoData } = useDailyReportHeaterRunTime(houseName, latestTimestamp, 7, "sum");
  const { data: heaterRunTimeDailySum6dAgoData } = useDailyReportHeaterRunTime(houseName, latestTimestamp, 6, "sum");
  const { data: heaterRunTimeDailySum5dAgoData } = useDailyReportHeaterRunTime(houseName, latestTimestamp, 5, "sum");
  const { data: heaterRunTimeDailySum4dAgoData } = useDailyReportHeaterRunTime(houseName, latestTimestamp, 4, "sum");
  const { data: heaterRunTimeDailySum3dAgoData } = useDailyReportHeaterRunTime(houseName, latestTimestamp, 3, "sum");
  const { data: heaterRunTimeDailySum2dAgoData } = useDailyReportHeaterRunTime(houseName, latestTimestamp, 2, "sum");
  const { data: heaterRunTimeDailySum1dAgoData } = useDailyReportHeaterRunTime(houseName, latestTimestamp, 1, "sum");

  const [ dailyFuelConsumptions, setDailyFuelConsumptions ] = useState([]);

  const [ plotData, setPlotData ] = useState([]);

  useEffect(() => {

    if (!latestTimestamp || !latestTimestamp instanceof Date) return;
    if (!displaySetting) return;

    setDailyData("/env/solar_irradiance", houseName, latestTimestamp, [
      setSolar7dAgoData, 
      setSolar6dAgoData, 
      setSolar5dAgoData,
      setSolar4dAgoData,
      setSolar3dAgoData,
      setSolar2dAgoData,
      setSolar1dAgoData,
    ]);

    const photoUrl = _useCanopy(displaySetting) ? "/sp2/db/canopy_photosynthetic_rate" : "/sp2/db/photosynthetic_rate";
    const transUrl = _useCanopy(displaySetting) ? "/sp2/db/canopy_transpiration_rate" : "/sp2/db/transpiration_rate";

    setDailyData(photoUrl, houseName, latestTimestamp, [
      setPhoto7dAgoData,
      setPhoto6dAgoData,
      setPhoto5dAgoData,
      setPhoto4dAgoData,
      setPhoto3dAgoData,
      setPhoto2dAgoData,
      setPhoto1dAgoData,
    ]);

    setDailyData(transUrl, houseName, latestTimestamp, [
      setTrans7dAgoData,
      setTrans6dAgoData,
      setTrans5dAgoData,
      setTrans4dAgoData,
      setTrans3dAgoData,
      setTrans2dAgoData,
      setTrans1dAgoData,
    ]);

  }, [ houseName, latestTimestamp, displaySetting ]);

  useEffect(() => {
    if (!latestTimestamp || !latestTimestamp instanceof Date) return;

    setMultipleEnvInfo(houseName, latestTimestamp, "mean", {
      "air_temperature": [
        setTempDailyMean7dAgoValue,
        setTempDailyMean6dAgoValue,
        setTempDailyMean5dAgoValue,
        setTempDailyMean4dAgoValue,
        setTempDailyMean3dAgoValue,
        setTempDailyMean2dAgoValue,
        setTempDailyMean1dAgoValue,
      ],
      "relative_humidity": [
        setHumDailyMean7dAgoValue,
        setHumDailyMean6dAgoValue,
        setHumDailyMean5dAgoValue,
        setHumDailyMean4dAgoValue,
        setHumDailyMean3dAgoValue,
        setHumDailyMean2dAgoValue,
        setHumDailyMean1dAgoValue,
      ],
    }).catch(error => console.log(error));

    setMultipleEnvInfo(houseName, latestTimestamp, "sum", {
      "irrigation_water_amounts": [
        setIrrigationDailySum7dAgoValue,
        setIrrigationDailySum6dAgoValue,
        setIrrigationDailySum5dAgoValue,
        setIrrigationDailySum4dAgoValue,
        setIrrigationDailySum3dAgoValue,
        setIrrigationDailySum2dAgoValue,
        setIrrigationDailySum1dAgoValue,
      ],
    }).catch(error => console.log(error));

  }, [ houseName, latestTimestamp, displaySetting ]);

  useEffect(() => {
    setDailyFuelConsumptions(heaters
      .reduce((accumulator, { name, fuelConsumption }) => {
        accumulator[0] += calculateFuelConsumption(heaterRunTimeDailySum7dAgoData[name], fuelConsumption);
        accumulator[1] += calculateFuelConsumption(heaterRunTimeDailySum6dAgoData[name], fuelConsumption);
        accumulator[2] += calculateFuelConsumption(heaterRunTimeDailySum5dAgoData[name], fuelConsumption);
        accumulator[3] += calculateFuelConsumption(heaterRunTimeDailySum4dAgoData[name], fuelConsumption);
        accumulator[4] += calculateFuelConsumption(heaterRunTimeDailySum3dAgoData[name], fuelConsumption);
        accumulator[5] += calculateFuelConsumption(heaterRunTimeDailySum2dAgoData[name], fuelConsumption);
        accumulator[6] += calculateFuelConsumption(heaterRunTimeDailySum1dAgoData[name], fuelConsumption);
        return accumulator;
      }, [ null, null, null, null, null, null, null ])
      .map(value => displayValue(value))
    );
    return () => setDailyFuelConsumptions([]);
  }, [
    heaters,
    heaterRunTimeDailySum7dAgoData,
    heaterRunTimeDailySum6dAgoData,
    heaterRunTimeDailySum5dAgoData,
    heaterRunTimeDailySum4dAgoData,
    heaterRunTimeDailySum3dAgoData,
    heaterRunTimeDailySum2dAgoData,
    heaterRunTimeDailySum1dAgoData
  ]);

  useEffect(() => {

      const x = [ "7日前", "6日前", "5日前", "4日前", "3日前", "2日前", "1日前" ];

      const tempDailyMaxPlotData = {
        type: "scatter",
        mode: "lines",
        name: "温度1日最高",
        x,
        y: [
          displayValue(tempDailyMax7dAgoValue),
          displayValue(tempDailyMax6dAgoValue),
          displayValue(tempDailyMax5dAgoValue),
          displayValue(tempDailyMax4dAgoValue),
          displayValue(tempDailyMax3dAgoValue),
          displayValue(tempDailyMax2dAgoValue),
          displayValue(tempDailyMax1dAgoValue)
        ],
        yaxis: "y",
        hovertemplate: "%{y} ℃"
      };

      const tempDailyMeanPlotData = {
        type: "scatter",
        mode: "lines",
        name: "温度1日平均",
        x,
        y: [
          displayValue(tempDailyMean7dAgoValue),
          displayValue(tempDailyMean6dAgoValue),
          displayValue(tempDailyMean5dAgoValue),
          displayValue(tempDailyMean4dAgoValue),
          displayValue(tempDailyMean3dAgoValue),
          displayValue(tempDailyMean2dAgoValue),
          displayValue(tempDailyMean1dAgoValue)
        ],
        yaxis: "y2",
        hovertemplate: "%{y} ℃"
      };

      const tempDailyMinPlotData = {
        type: "scatter",
        mode: "lines",
        name: "温度1日最低",
        x,
        y: [
          displayValue(tempDailyMin7dAgoValue),
          displayValue(tempDailyMin6dAgoValue),
          displayValue(tempDailyMin5dAgoValue),
          displayValue(tempDailyMin4dAgoValue),
          displayValue(tempDailyMin3dAgoValue),
          displayValue(tempDailyMin2dAgoValue),
          displayValue(tempDailyMin1dAgoValue)
        ],
        yaxis: "y3",
        hovertemplate: "%{y} ℃"
      };

      const tempDaytimeMeanPlotData = {
        type: "scatter",
        mode: "lines",
        name: "温度日中平均",
        x,
        y: [
          displayValue(tempDaytimeMean7dAgoValue),
          displayValue(tempDaytimeMean6dAgoValue),
          displayValue(tempDaytimeMean5dAgoValue),
          displayValue(tempDaytimeMean4dAgoValue),
          displayValue(tempDaytimeMean3dAgoValue),
          displayValue(tempDaytimeMean2dAgoValue),
          displayValue(tempDaytimeMean1dAgoValue),
        ],
        yaxis: "y4",
        hovertemplate: "%{y} ℃"
      };

      const tempNighttimeMeanPlotData = {
        type: "scatter",
        mode: "lines",
        name: "温度夜間平均",
        x,
        y: [
          displayValue(tempNighttimeMean7dAgoValue),
          displayValue(tempNighttimeMean6dAgoValue),
          displayValue(tempNighttimeMean5dAgoValue),
          displayValue(tempNighttimeMean4dAgoValue),
          displayValue(tempNighttimeMean3dAgoValue),
          displayValue(tempNighttimeMean2dAgoValue),
          displayValue(tempNighttimeMean1dAgoValue),
        ],
        yaxis: "y5",
        hovertemplate: "%{y} ℃"
      };

      const humDailyMeanPlotData = {
        type: "scatter",
        mode: "lines",
        name: "湿度1日平均",
        x,
        y: [
          displayValue(humDailyMean7dAgoValue),
          displayValue(humDailyMean6dAgoValue),
          displayValue(humDailyMean5dAgoValue),
          displayValue(humDailyMean4dAgoValue),
          displayValue(humDailyMean3dAgoValue),
          displayValue(humDailyMean2dAgoValue),
          displayValue(humDailyMean1dAgoValue),
        ],
        yaxis: "y6",
        hovertemplate: "%{y} %RH"
      };

      const vpdDailyMeanPlotData = {
        type: "scatter",
        mode: "lines",
        name: "飽差1日平均",
        x,
        y: [
          displayValue(convertToVaporPressureDeficit(tempDailyMean7dAgoValue, humDailyMean7dAgoValue)),
          displayValue(convertToVaporPressureDeficit(tempDailyMean6dAgoValue, humDailyMean6dAgoValue)),
          displayValue(convertToVaporPressureDeficit(tempDailyMean5dAgoValue, humDailyMean5dAgoValue)),
          displayValue(convertToVaporPressureDeficit(tempDailyMean4dAgoValue, humDailyMean4dAgoValue)),
          displayValue(convertToVaporPressureDeficit(tempDailyMean3dAgoValue, humDailyMean3dAgoValue)),
          displayValue(convertToVaporPressureDeficit(tempDailyMean2dAgoValue, humDailyMean2dAgoValue)),
          displayValue(convertToVaporPressureDeficit(tempDailyMean1dAgoValue, humDailyMean1dAgoValue)),
        ],
        yaxis: "y7",
        hovertemplate: "%{y} g/m3"
      };

      const co2DaytimeMeanPlotData = {
        type: "scatter",
        mode: "lines",
        name: "CO2日中平均",
        x,
        y: [
          displayValue(co2DaytimeMean7dAgoValue),
          displayValue(co2DaytimeMean6dAgoValue),
          displayValue(co2DaytimeMean5dAgoValue),
          displayValue(co2DaytimeMean4dAgoValue),
          displayValue(co2DaytimeMean3dAgoValue),
          displayValue(co2DaytimeMean2dAgoValue),
          displayValue(co2DaytimeMean1dAgoValue),
        ],
        yaxis: "y8",
        hovertemplate: "%{y} ppm"
      };

      const solarDailySumPlotData = {
        type: "scatter",
        mode: "lines",
        name: "日射量1日積算",
        x,
        y: [
          displayValue(accumulateSolarIrradiancePer10a(solar7dAgoData)),
          displayValue(accumulateSolarIrradiancePer10a(solar6dAgoData)),
          displayValue(accumulateSolarIrradiancePer10a(solar5dAgoData)),
          displayValue(accumulateSolarIrradiancePer10a(solar4dAgoData)),
          displayValue(accumulateSolarIrradiancePer10a(solar3dAgoData)),
          displayValue(accumulateSolarIrradiancePer10a(solar2dAgoData)),
          displayValue(accumulateSolarIrradiancePer10a(solar1dAgoData)),
        ],
        yaxis: "y9",
        hovertemplate: "%{y} MJ/m2"
      };

      const irrigationDailySumPlotData = {
        type: "scatter",
        mode: "lines",
        name: "かん水実績1日積算",
        x,
        y: [
          displayValue(per10a(irrigationDailySum7dAgoValue, arableLandArea)),
          displayValue(per10a(irrigationDailySum6dAgoValue, arableLandArea)),
          displayValue(per10a(irrigationDailySum5dAgoValue, arableLandArea)),
          displayValue(per10a(irrigationDailySum4dAgoValue, arableLandArea)),
          displayValue(per10a(irrigationDailySum3dAgoValue, arableLandArea)),
          displayValue(per10a(irrigationDailySum2dAgoValue, arableLandArea)),
          displayValue(per10a(irrigationDailySum1dAgoValue, arableLandArea)),
        ],
        yaxis: "y10",
        hovertemplate: "%{y} t/10a"
      };

      const fuelConsumptionDailySumPlotData = {
        type: "scatter",
        mode: "lines",
        name: "燃料消費量1日積算",
        x,
        y: dailyFuelConsumptions,
        yaxis: "y11",
        hovertemplate: "%{y} L"
      };

      const transDailySumPlotData = {
        type: "scatter",
        mode: "lines",
        name: "蒸散量1日積算",
        x,
        y: [
          displayValue(convertRateToAmountOfTranspirationPer10a(trans7dAgoData)),
          displayValue(convertRateToAmountOfTranspirationPer10a(trans6dAgoData)),
          displayValue(convertRateToAmountOfTranspirationPer10a(trans5dAgoData)),
          displayValue(convertRateToAmountOfTranspirationPer10a(trans4dAgoData)),
          displayValue(convertRateToAmountOfTranspirationPer10a(trans3dAgoData)),
          displayValue(convertRateToAmountOfTranspirationPer10a(trans2dAgoData)),
          displayValue(convertRateToAmountOfTranspirationPer10a(trans1dAgoData)),
        ],
        yaxis: "y12",
        hovertemplate: "%{y} t/10a"
      };

      const laiDailyMaxPlotData = {
        type: "scatter",
        mode: "lines",
        name: "LAI最大値",
        x,
        y: [
          displayValue(laiDailyMax7dAgoValue),
          displayValue(laiDailyMax6dAgoValue),
          displayValue(laiDailyMax5dAgoValue),
          displayValue(laiDailyMax4dAgoValue),
          displayValue(laiDailyMax3dAgoValue),
          displayValue(laiDailyMax2dAgoValue),
          displayValue(laiDailyMax1dAgoValue),
        ],
        yaxis: "y13",
        hovertemplate: "%{y} m2/m2"
      };

      const carbonDailySumPlotData = {
        type: "scatter",
        mode: "lines",
        name: "炭素収支1日積算",
        x,
        y: [
          displayValue(convertToCarbonBudgetPer10a(photo7dAgoData)),
          displayValue(convertToCarbonBudgetPer10a(photo6dAgoData)),
          displayValue(convertToCarbonBudgetPer10a(photo5dAgoData)),
          displayValue(convertToCarbonBudgetPer10a(photo4dAgoData)),
          displayValue(convertToCarbonBudgetPer10a(photo3dAgoData)),
          displayValue(convertToCarbonBudgetPer10a(photo2dAgoData)),
          displayValue(convertToCarbonBudgetPer10a(photo1dAgoData)),
        ],
        yaxis: "y14",
        hovertemplate: "%{y} kg/10a"
      };

      const basePlotData = [
        tempDailyMaxPlotData,
        tempDailyMeanPlotData,
        tempDailyMinPlotData
      ];

      if (isVisible(displaySetting, "tempDaytimeMean")) {
        basePlotData.push(tempDaytimeMeanPlotData);
      }

      if (isVisible(displaySetting, "tempNighttimeMean")) {
        basePlotData.push(tempNighttimeMeanPlotData);
      }

      if (isVisible(displaySetting, "humDailyMean")) {
        basePlotData.push(humDailyMeanPlotData);
      }

      if (isVisible(displaySetting, "vpdDailyMean")) {
        basePlotData.push(vpdDailyMeanPlotData);
      }

      if (isVisible(displaySetting, "co2DaytimeMean")) {
        basePlotData.push(co2DaytimeMeanPlotData);
      }

      if (isVisible(displaySetting, "solarDailySum")) {
        basePlotData.push(solarDailySumPlotData);
      }

      if (isVisible(displaySetting, "irrigationDailySum")) {
        basePlotData.push(irrigationDailySumPlotData);
      }

      if (isVisible(displaySetting, "fuelConsumptionDailySum")) {
        basePlotData.push(fuelConsumptionDailySumPlotData);
      }

      if (isVisible(displaySetting, "transDailySum")) {
        basePlotData.push(transDailySumPlotData);
      }

      if (isVisible(displaySetting, "laiDailyMax")) {
        basePlotData.push(laiDailyMaxPlotData);
      }

      if (isVisible(displaySetting, "carbonDailySum")) {
        basePlotData.push(carbonDailySumPlotData);
      }

      setPlotData(basePlotData);
  }, [
    displaySetting,
    tempDailyMax7dAgoValue,
    tempDailyMax6dAgoValue,
    tempDailyMax5dAgoValue,
    tempDailyMax4dAgoValue,
    tempDailyMax3dAgoValue,
    tempDailyMax2dAgoValue,
    tempDailyMax1dAgoValue,
    tempDailyMean7dAgoValue,
    tempDailyMean6dAgoValue,
    tempDailyMean5dAgoValue,
    tempDailyMean4dAgoValue,
    tempDailyMean3dAgoValue,
    tempDailyMean2dAgoValue,
    tempDailyMean1dAgoValue,
    tempDailyMin7dAgoValue,
    tempDailyMin6dAgoValue,
    tempDailyMin5dAgoValue,
    tempDailyMin4dAgoValue,
    tempDailyMin3dAgoValue,
    tempDailyMin2dAgoValue,
    tempDailyMin1dAgoValue,
    tempDaytimeMean7dAgoValue,
    tempDaytimeMean6dAgoValue,
    tempDaytimeMean5dAgoValue,
    tempDaytimeMean4dAgoValue,
    tempDaytimeMean3dAgoValue,
    tempDaytimeMean2dAgoValue,
    tempDaytimeMean1dAgoValue,
    tempNighttimeMean7dAgoValue,
    tempNighttimeMean6dAgoValue,
    tempNighttimeMean5dAgoValue,
    tempNighttimeMean4dAgoValue,
    tempNighttimeMean3dAgoValue,
    tempNighttimeMean2dAgoValue,
    tempNighttimeMean1dAgoValue,
    humDailyMean7dAgoValue,
    humDailyMean6dAgoValue,
    humDailyMean5dAgoValue,
    humDailyMean4dAgoValue,
    humDailyMean3dAgoValue,
    humDailyMean2dAgoValue,
    humDailyMean1dAgoValue,
    co2DaytimeMean7dAgoValue,
    co2DaytimeMean6dAgoValue,
    co2DaytimeMean5dAgoValue,
    co2DaytimeMean4dAgoValue,
    co2DaytimeMean3dAgoValue,
    co2DaytimeMean2dAgoValue,
    co2DaytimeMean1dAgoValue,
    solar7dAgoData,
    solar6dAgoData,
    solar5dAgoData,
    solar4dAgoData,
    solar3dAgoData,
    solar2dAgoData,
    solar1dAgoData,
    irrigationDailySum7dAgoValue,
    irrigationDailySum6dAgoValue,
    irrigationDailySum5dAgoValue,
    irrigationDailySum4dAgoValue,
    irrigationDailySum3dAgoValue,
    irrigationDailySum2dAgoValue,
    irrigationDailySum1dAgoValue,
    arableLandArea,
    dailyFuelConsumptions,
    trans7dAgoData,
    trans6dAgoData,
    trans5dAgoData,
    trans4dAgoData,
    trans3dAgoData,
    trans2dAgoData,
    trans1dAgoData,
    laiDailyMax7dAgoValue,
    laiDailyMax6dAgoValue,
    laiDailyMax5dAgoValue,
    laiDailyMax4dAgoValue,
    laiDailyMax3dAgoValue,
    laiDailyMax2dAgoValue,
    laiDailyMax1dAgoValue,
    photo7dAgoData,
    photo6dAgoData,
    photo5dAgoData,
    photo4dAgoData,
    photo3dAgoData,
    photo2dAgoData,
    photo1dAgoData,
  ]);

  // レイアウトが崩れるので小数点第一位までしか表示しない。
  const displayValue = value => (value === null) ? "N/A" : Math.round(value * 10) / 10;

  /*
   * 日射量の1日の平均値(W/m2 = J/m2/s) × 1日の秒数(s) = 1日の積算日射量(J/m2)
   * 1日の積算日射量(J/m2) / 1000000 = 1日の積算日射量(MJ/m2)
  */
  const accumulateSolarIrradiancePer10a = data => {
    if (!data || data.length === 0) return null;
    const sum = data.reduce((accumulator, { value }) => accumulator + value, 0);
    const mean = sum / data.length;
    const diffSeconds = differenceInSeconds(new Date(data[1].timestamp), new Date(data[0].timestamp));
    return mean * (diffSeconds * data.length) / 1000000;
  };

  /*
   * 光合成速度は単位がμmol/㎡/s。
   * 1㎡にある植物が、1秒間に何μmolのCO2を吸収したかで表される。
   * 1molのCO2は44gで1μmolはその100万分の1である。
   * 例えば光合成速度が25μmol/㎡/sである状態が1時間続くと、
   * 1時間でのCO2吸収量は約3.96g/㎡=3.96kg/10aとなる。
   * これが分かると、1時間当たりのCO2施用量の目安にもなると思われる。
  */
  const convertToCarbonBudgetPer10a = data => {
    if (!data || data.length === 0) return null;
    const sum = data.reduce((accumulator, { value }) => accumulator + value, 0);
    const mean = sum / data.length;
    const diffSeconds = differenceInSeconds(new Date(data[1].timestamp), new Date(data[0].timestamp));
    const gramPerMicroMol = 44 / 1000000;
    return mean * (diffSeconds * data.length) * gramPerMicroMol;
  };

  /*
   * 蒸散速度は単位がmmol/㎡/s。
   * 1㎡にある植物が、1秒間に何mmolの水を放出したかで表さる。
   * 1molの水は18mlで1mmolはその1000分の1である。
   * 例えば5mmol/㎡/sである状態が1時間続くと、
   * 1時間での蒸散量は約324ml/㎡=324L/10aのとなる。
   * 1Lの水は1kgなので324L/10a=0.324t/10aである。
   * これが分かると、かん水量の目安として活用できると思われる。
  */
  const convertRateToAmountOfTranspirationPer10a = data => {
    if (!data || data.length === 0) return null;
    const sum = data.reduce((accumulator, { value }) => accumulator + value, 0);
    const mean = sum / data.length;
    const diffSeconds = differenceInSeconds(new Date(data[1].timestamp), new Date(data[0].timestamp));
    const mlPerMilliMol = 18 / 1000;
    return mean * (diffSeconds * data.length) * mlPerMilliMol / 1000;
  };

  const mean = (numbers) => {
    let sum = 0;
    let count = 0;
    for (let i = 0; i < numbers.length; i++) {
      if (typeof numbers[i] === 'number') {
        sum += numbers[i];
        count++;
      }
    }
    if (count === 0) {
      return null;
    }
    return sum / count;
  };

  const sum = (numbers) => {
    return numbers.reduce((sum, value) => {
      if (typeof value === 'number') {
        sum += value;
      }
      return sum;
    }, null);
  };

  const getTempLabelRowSpan = (displaySetting) => {
    if (!displaySetting) return 6;
    if (!displaySetting["home"]) return 6;
    if (!displaySetting["home"]["dailyReport"]) return 6;
    if (!displaySetting["home"]["dailyReport"]["visible"]) return 6;
    let rowSpan = 6;
    const visible = displaySetting["home"]["dailyReport"]["visible"];
    if (visible["tempDaytimeMean"] === false) --rowSpan;
    if (visible["tempNighttimeMean"] === false) --rowSpan;
    return rowSpan;
  };

  const per10a = (value, arableLandArea) => {
    if (!Number.isFinite(value) || !Number.isFinite(arableLandArea)) return null;
    if (arableLandArea <= 0) return null;
    return value / arableLandArea * 10;
  };

  const calculateFuelConsumption = (heaterRunTimeDailySum, fuelConsumption) => {
    return fuelConsumption * heaterRunTimeDailySum / 60;
  };

  const plotLayout = {
    autosize: true,
    hovermode: "x unified",
    dragmode: false,
    yaxis: {
      visible: false,
    },
    yaxis2: {
      overlaying: 'y',
      visible: false,
    },
    yaxis3: {
      overlaying: 'y',
      visible: false,
    },
    yaxis4: {
      overlaying: 'y',
      visible: false,
    },
    yaxis5: {
      overlaying: 'y',
      visible: false,
    },
    yaxis6: {
      overlaying: 'y',
      visible: false,
    },
    yaxis7: {
      overlaying: 'y',
      visible: false,
    },
    yaxis8: {
      overlaying: 'y',
      visible: false,
    },
    yaxis9: {
      overlaying: 'y',
      visible: false,
    },
    yaxis10: {
      overlaying: 'y',
      visible: false,
    },
    yaxis11: {
      overlaying: 'y',
      visible: false,
    },
    yaxis12: {
      overlaying: 'y',
      visible: false,
    },
    yaxis13: {
      overlaying: 'y',
      visible: false,
    },
    yaxis14: {
      overlaying: 'y',
      visible: false,
    },
  };

  const plotConfig = {
    displayModeBar: false
  };

  return (
    <div>
      <table className="daily-report-table">
        <thead>
          <tr>
            <th className="border-none" colSpan={3}></th>
            <th className="daily-report-3d-ago-label" scope="col">３日前</th>
            <th className="daily-report-2d-ago-label" scope="col">２日前</th>
            <th className="daily-report-1d-ago-label" scope="col">１日前</th>
            <th className="daily-report-7days-label" scope="col">７日分</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className="daily-report-temp-label" rowSpan={getTempLabelRowSpan(displaySetting)}>温度</th>
            <th rowSpan={4}>１日</th>
          </tr>
          <tr>
            <th className="daily-report-temp-daily-max-label">最高</th>
            <td className="daily-report-temp-daily-max-3d-ago">{displayValue(tempDailyMax3dAgoValue)}<br className="sp-br" /> <span className="daily-report-unit">℃</span></td>
            <td className="daily-report-temp-daily-max-2d-ago">{displayValue(tempDailyMax2dAgoValue)}<br className="sp-br" /> <span className="daily-report-unit">℃</span></td>
            <td className="daily-report-temp-daily-max-1d-ago">{displayValue(tempDailyMax1dAgoValue)}<br className="sp-br" /> <span className="daily-report-unit">℃</span></td>
            <td className="daily-report-temp-daily-max-7days">
              {
                displayValue(Math.max(
                  tempDailyMax1dAgoValue,
                  tempDailyMax2dAgoValue,
                  tempDailyMax3dAgoValue,
                  tempDailyMax4dAgoValue,
                  tempDailyMax5dAgoValue,
                  tempDailyMax6dAgoValue,
                  tempDailyMax7dAgoValue
                ))
              }<br className="sp-br" /> <span className="daily-report-unit">℃</span>
            </td>
          </tr>
          <tr>
            <th className="daily-report-temp-daily-mean-label">平均</th>
            <td className="daily-report-temp-daily-mean-3d-ago">{displayValue(tempDailyMean3dAgoValue)}<br className="sp-br" /> <span className="daily-report-unit">℃</span></td>
            <td className="daily-report-temp-daily-mean-2d-ago">{displayValue(tempDailyMean2dAgoValue)}<br className="sp-br" /> <span className="daily-report-unit">℃</span></td>
            <td className="daily-report-temp-daily-mean-1d-ago">{displayValue(tempDailyMean1dAgoValue)}<br className="sp-br" /> <span className="daily-report-unit">℃</span></td>
            <td className="daily-report-temp-daily-mean-7days">
              {
                displayValue(mean([
                  tempDailyMean1dAgoValue,
                  tempDailyMean2dAgoValue,
                  tempDailyMean3dAgoValue,
                  tempDailyMean4dAgoValue,
                  tempDailyMean5dAgoValue,
                  tempDailyMean6dAgoValue,
                  tempDailyMean7dAgoValue,
                ]))
              }<br className="sp-br" /> <span className="daily-report-unit">℃</span>
            </td>
          </tr>
          <tr>
            <th className="daily-report-temp-daily-min-label">最低</th>
            <td className="daily-report-temp-daily-min-3d-ago">{displayValue(tempDailyMin3dAgoValue)}<br className="sp-br" /> <span className="daily-report-unit">℃</span></td>
            <td className="daily-report-temp-daily-min-2d-ago">{displayValue(tempDailyMin2dAgoValue)}<br className="sp-br" /> <span className="daily-report-unit">℃</span></td>
            <td className="daily-report-temp-daily-min-1d-ago">{displayValue(tempDailyMin1dAgoValue)}<br className="sp-br" /> <span className="daily-report-unit">℃</span></td>
            <td className="daily-report-temp-daily-min-7days">
              {
                displayValue(Math.min(
                  tempDailyMin1dAgoValue,
                  tempDailyMin2dAgoValue,
                  tempDailyMin3dAgoValue,
                  tempDailyMin4dAgoValue,
                  tempDailyMin5dAgoValue,
                  tempDailyMin6dAgoValue,
                  tempDailyMin7dAgoValue,
                ))
              }<br className="sp-br" /> <span className="daily-report-unit">℃</span>
            </td>
          </tr>
          { isVisible(displaySetting, "tempDaytimeMean") && (
            <tr>
              <th colSpan={2}>日中平均<br />（７〜１７時）</th>
              <td>{displayValue(tempDaytimeMean3dAgoValue)}<br className="sp-br" /> <span className="daily-report-unit">℃</span></td>
              <td>{displayValue(tempDaytimeMean2dAgoValue)}<br className="sp-br" /> <span className="daily-report-unit">℃</span></td>
              <td>{displayValue(tempDaytimeMean1dAgoValue)}<br className="sp-br" /> <span className="daily-report-unit">℃</span></td>
              <td>
                {
                  displayValue(mean([
                    tempDaytimeMean1dAgoValue,
                    tempDaytimeMean2dAgoValue,
                    tempDaytimeMean3dAgoValue,
                    tempDaytimeMean4dAgoValue,
                    tempDaytimeMean5dAgoValue,
                    tempDaytimeMean6dAgoValue,
                    tempDaytimeMean7dAgoValue,
                  ]))
                }<br className="sp-br" /> <span className="daily-report-unit">℃</span>
              </td>
            </tr>
          )}
          { isVisible(displaySetting, "tempNighttimeMean") && (
            <tr>
              <th colSpan={2}>夜間平均<br />（１８〜６時）</th>
              <td>{displayValue(tempNighttimeMean3dAgoValue)}<br className="sp-br" /> <span className="daily-report-unit">℃</span></td>
              <td>{displayValue(tempNighttimeMean2dAgoValue)}<br className="sp-br" /> <span className="daily-report-unit">℃</span></td>
              <td>{displayValue(tempNighttimeMean1dAgoValue)}<br className="sp-br" /> <span className="daily-report-unit">℃</span></td>
              <td>
                {
                  displayValue(mean([
                    tempNighttimeMean1dAgoValue,
                    tempNighttimeMean2dAgoValue,
                    tempNighttimeMean3dAgoValue,
                    tempNighttimeMean4dAgoValue,
                    tempNighttimeMean5dAgoValue,
                    tempNighttimeMean6dAgoValue,
                    tempNighttimeMean7dAgoValue,
                  ]))
                }<br className="sp-br" /> <span className="daily-report-unit">℃</span>
              </td>
            </tr>
          )}
          { isVisible(displaySetting, "humDailyMean") && (
            <tr>
              <th className="daily-report-hum-label">湿度</th>
              <th colSpan={2}>１日平均</th>
              <td>{displayValue(humDailyMean3dAgoValue)}<br className="sp-br" /> <span className="daily-report-unit">%RH</span></td>
              <td>{displayValue(humDailyMean2dAgoValue)}<br className="sp-br" /> <span className="daily-report-unit">%RH</span></td>
              <td>{displayValue(humDailyMean1dAgoValue)}<br className="sp-br" /> <span className="daily-report-unit">%RH</span></td>
              <td>
                {
                  displayValue(mean([
                    humDailyMean1dAgoValue,
                    humDailyMean2dAgoValue,
                    humDailyMean3dAgoValue,
                    humDailyMean4dAgoValue,
                    humDailyMean5dAgoValue,
                    humDailyMean6dAgoValue,
                    humDailyMean7dAgoValue,
                  ]))
                }<br className="sp-br" /> <span className="daily-report-unit">%RH</span>
              </td>
            </tr>
          )}
          { isVisible(displaySetting, "vpdDailyMean") && (
            <tr>
              <th className="daily-report-vpd-label">飽差</th>
              <th colSpan={2}>１日平均</th>
              <td>{displayValue(convertToVaporPressureDeficit(tempDailyMean3dAgoValue, humDailyMean3dAgoValue))}<br className="sp-br" /> <span className="daily-report-unit">g/m3</span></td>
              <td>{displayValue(convertToVaporPressureDeficit(tempDailyMean2dAgoValue, humDailyMean2dAgoValue))}<br className="sp-br" /> <span className="daily-report-unit">g/m3</span></td>
              <td>{displayValue(convertToVaporPressureDeficit(tempDailyMean1dAgoValue, humDailyMean1dAgoValue))}<br className="sp-br" /> <span className="daily-report-unit">g/m3</span></td>
              <td>
                {
                  displayValue(mean([
                    convertToVaporPressureDeficit(tempDailyMean1dAgoValue, humDailyMean1dAgoValue),
                    convertToVaporPressureDeficit(tempDailyMean2dAgoValue, humDailyMean2dAgoValue),
                    convertToVaporPressureDeficit(tempDailyMean3dAgoValue, humDailyMean3dAgoValue),
                    convertToVaporPressureDeficit(tempDailyMean4dAgoValue, humDailyMean4dAgoValue),
                    convertToVaporPressureDeficit(tempDailyMean5dAgoValue, humDailyMean5dAgoValue),
                    convertToVaporPressureDeficit(tempDailyMean6dAgoValue, humDailyMean6dAgoValue),
                    convertToVaporPressureDeficit(tempDailyMean7dAgoValue, humDailyMean7dAgoValue),
                  ]))
                }<br className="sp-br" /> <span className="daily-report-unit">g/m3</span>
              </td>
            </tr>
          )}
          { isVisible(displaySetting, "co2DaytimeMean") && (
            <tr>
              <th className="daily-report-co2-label">CO2</th>
              <th colSpan={2}>日中平均<br />（９〜１５時）</th>
              <td>{displayValue(co2DaytimeMean3dAgoValue)}<br className="sp-br" /> <span className="daily-report-unit">ppm</span></td>
              <td>{displayValue(co2DaytimeMean2dAgoValue)}<br className="sp-br" /> <span className="daily-report-unit">ppm</span></td>
              <td>{displayValue(co2DaytimeMean1dAgoValue)}<br className="sp-br" /> <span className="daily-report-unit">ppm</span></td>
              <td>
                {
                  displayValue(mean([
                    co2DaytimeMean1dAgoValue,
                    co2DaytimeMean2dAgoValue,
                    co2DaytimeMean3dAgoValue,
                    co2DaytimeMean4dAgoValue,
                    co2DaytimeMean5dAgoValue,
                    co2DaytimeMean6dAgoValue,
                    co2DaytimeMean7dAgoValue,
                  ]))
                }<br className="sp-br" /> <span className="daily-report-unit">ppm</span>
              </td>
            </tr>
          )}
          { isVisible(displaySetting, "solarDailySum") && (
            <tr>
              <th className="daily-report-solar-label">日射量</th>
              <th colSpan={2}>１日積算</th>
              <td>{displayValue(accumulateSolarIrradiancePer10a(solar3dAgoData))}<br className="sp-br" /> <span className="daily-report-unit">MJ/m2</span></td>
              <td>{displayValue(accumulateSolarIrradiancePer10a(solar2dAgoData))}<br className="sp-br" /> <span className="daily-report-unit">MJ/m2</span></td>
              <td>{displayValue(accumulateSolarIrradiancePer10a(solar1dAgoData))}<br className="sp-br" /> <span className="daily-report-unit">MJ/m2</span></td>
              <td>
                {
                  displayValue(sum([
                    accumulateSolarIrradiancePer10a(solar1dAgoData),
                    accumulateSolarIrradiancePer10a(solar2dAgoData),
                    accumulateSolarIrradiancePer10a(solar3dAgoData),
                    accumulateSolarIrradiancePer10a(solar4dAgoData),
                    accumulateSolarIrradiancePer10a(solar5dAgoData),
                    accumulateSolarIrradiancePer10a(solar6dAgoData),
                    accumulateSolarIrradiancePer10a(solar7dAgoData),
                  ]))
                }<br className="sp-br" /> <span className="daily-report-unit">MJ/m2</span>
              </td>
            </tr>
          )}
          { isVisible(displaySetting, "irrigationDailySum") && (
            <tr>
              <th className="daily-report-irrigation-label">かん水実績</th>
              <th colSpan={2}>１日積算</th>
              <td>{displayValue(per10a(irrigationDailySum3dAgoValue, arableLandArea))}<br className="sp-br" /> <span className="daily-report-unit">t/10a</span></td>
              <td>{displayValue(per10a(irrigationDailySum2dAgoValue, arableLandArea))}<br className="sp-br" /> <span className="daily-report-unit">t/10a</span></td>
              <td>{displayValue(per10a(irrigationDailySum1dAgoValue, arableLandArea))}<br className="sp-br" /> <span className="daily-report-unit">t/10a</span></td>
              <td>
                {
                  displayValue(sum([
                    per10a(irrigationDailySum1dAgoValue, arableLandArea),
                    per10a(irrigationDailySum2dAgoValue, arableLandArea),
                    per10a(irrigationDailySum3dAgoValue, arableLandArea),
                    per10a(irrigationDailySum4dAgoValue, arableLandArea),
                    per10a(irrigationDailySum5dAgoValue, arableLandArea),
                    per10a(irrigationDailySum6dAgoValue, arableLandArea),
                    per10a(irrigationDailySum7dAgoValue, arableLandArea),
                  ]))
                }<br className="sp-br" /> <span className="daily-report-unit">t/10a</span>
              </td>
            </tr>
          )}
          { isVisible(displaySetting, "fuelConsumptionDailySum") && (
            <tr>
              <th className="daily-report-fuel-consumption-label">燃料消費量</th>
              <th colSpan={2}>１日積算</th>
              <td>{displayValue(dailyFuelConsumptions[4])}<br className="sp-br" /> <span className="daily-report-unit">L</span></td>
              <td>{displayValue(dailyFuelConsumptions[5])}<br className="sp-br" /> <span className="daily-report-unit">L</span></td>
              <td>{displayValue(dailyFuelConsumptions[6])}<br className="sp-br" /> <span className="daily-report-unit">L</span></td>
              <td>{displayValue(sum(dailyFuelConsumptions))}<br className="sp-br" /> <span className="daily-report-unit">L</span></td>
            </tr>
          )}
          { isVisible(displaySetting, "transDailySum") && (
            <tr>
              <th className="daily-report-trans-label">蒸散量</th>
              <th colSpan={2}>１日積算</th>
              <td>{displayValue(convertRateToAmountOfTranspirationPer10a(trans3dAgoData))}<br className="sp-br" /> <span className="daily-report-unit">t/10a</span></td>
              <td>{displayValue(convertRateToAmountOfTranspirationPer10a(trans2dAgoData))}<br className="sp-br" /> <span className="daily-report-unit">t/10a</span></td>
              <td>{displayValue(convertRateToAmountOfTranspirationPer10a(trans1dAgoData))}<br className="sp-br" /> <span className="daily-report-unit">t/10a</span></td>
              <td>
                {
                  displayValue(sum([
                    convertRateToAmountOfTranspirationPer10a(trans1dAgoData),
                    convertRateToAmountOfTranspirationPer10a(trans2dAgoData),
                    convertRateToAmountOfTranspirationPer10a(trans3dAgoData),
                    convertRateToAmountOfTranspirationPer10a(trans4dAgoData),
                    convertRateToAmountOfTranspirationPer10a(trans5dAgoData),
                    convertRateToAmountOfTranspirationPer10a(trans6dAgoData),
                    convertRateToAmountOfTranspirationPer10a(trans7dAgoData),
                  ]))
                }<br className="sp-br" /> <span className="daily-report-unit">t/10a</span>
              </td>
            </tr>
          )}
          { isVisible(displaySetting, "laiDailyMax") && (
            <tr>
              <th className="daily-report-lai-label">LAI</th>
              <th colSpan={2}>最大値</th>
              <td>{displayValue(laiDailyMax3dAgoValue)}<br className="sp-br" /> <span className="daily-report-unit">m2/m2</span></td>
              <td>{displayValue(laiDailyMax2dAgoValue)}<br className="sp-br" /> <span className="daily-report-unit">m2/m2</span></td>
              <td>{displayValue(laiDailyMax1dAgoValue)}<br className="sp-br" /> <span className="daily-report-unit">m2/m2</span></td>
              <td>
                {
                  displayValue(Math.max(
                    laiDailyMax1dAgoValue,
                    laiDailyMax2dAgoValue,
                    laiDailyMax3dAgoValue,
                    laiDailyMax4dAgoValue,
                    laiDailyMax5dAgoValue,
                    laiDailyMax6dAgoValue,
                    laiDailyMax7dAgoValue,
                  ))
                }<br className="sp-br" /> <span className="daily-report-unit">m2/m2</span>
              </td>
            </tr>
          )}
          { isVisible(displaySetting, "carbonDailySum") && (
            <tr>
              <th className="daily-report-carbon-label">炭素収支</th>
              <th colSpan={2}>１日積算</th>
              <td>{displayValue(convertToCarbonBudgetPer10a(photo3dAgoData))}<br className="sp-br" /> <span className="daily-report-unit">kg/10a</span></td>
              <td>{displayValue(convertToCarbonBudgetPer10a(photo2dAgoData))}<br className="sp-br" /> <span className="daily-report-unit">kg/10a</span></td>
              <td>{displayValue(convertToCarbonBudgetPer10a(photo1dAgoData))}<br className="sp-br" /> <span className="daily-report-unit">kg/10a</span></td>
              <td>
                {
                  displayValue(sum([
                    convertToCarbonBudgetPer10a(photo1dAgoData),
                    convertToCarbonBudgetPer10a(photo2dAgoData),
                    convertToCarbonBudgetPer10a(photo3dAgoData),
                    convertToCarbonBudgetPer10a(photo4dAgoData),
                    convertToCarbonBudgetPer10a(photo5dAgoData),
                    convertToCarbonBudgetPer10a(photo6dAgoData),
                    convertToCarbonBudgetPer10a(photo7dAgoData),
                  ]))
                }<br className="sp-br" /> <span className="daily-report-unit">kg/10a</span></td>
            </tr>
          )}
        </tbody>
      </table>
      <div>
        <Plot
          data={plotData}
          layout={plotLayout}
          config={plotConfig}
          style={{ width: "100%", height: "100%" }}
          useResizeHandler={true}
        />
      </div>
    </div>
  );
}
