import { useState, useEffect, useCallback } from "react";
import client from "../api/client";

export default function useLatestEnvInfos(houseName, targets, startDate = undefined) {

  const [value, setValue] = useState(null);

  const handleSetValue = useCallback(() => {
    const params = {
      house_name: houseName,
      targets,
      start: startDate && startDate.toISOString(),
      select: "latest"
    };
    client.get("/env", { params })
      .then(response => {
        if (response.data) {
          setValue(response.data);
        }
      })
      .catch(error => console.log(error));
  }, [ houseName, targets, startDate ]);

  useEffect(() => {
    handleSetValue();
    const pollingInterval = 1000 * Number.parseInt(process.env.REACT_APP_POLLING_INTERVAL);
    const interval = setInterval(handleSetValue, pollingInterval);
    return () => {
      clearInterval(interval);
      setValue(null);
    };
  }, [ handleSetValue ]);

  return { value };
};
