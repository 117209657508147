import { useState, useEffect, useCallback } from "react";
import { subDays, startOfDay, endOfDay } from "date-fns";
import client from "../api/client";

const SELECT_OPTIONS = [ "max", "mean", "min", "sum" ];

const getTimeSlot = (latestTimestamp, daysAgo) => {
  const baseDate = subDays(latestTimestamp, daysAgo);
  const startDate = startOfDay(baseDate);
  const endDate = endOfDay(baseDate);
  return [startDate, endDate];
};

export default function useDailyReportHeaterRunTime(houseName, latestTimestamp, daysAgo, select, polling = false) {

  const [data, setData] = useState([]);

  const handleSetData = useCallback(() => {
    const [startDate, endDate] = getTimeSlot(latestTimestamp, daysAgo);
    const params = {
      house_name: houseName,
      start: startDate.toISOString(),
      end: endDate.toISOString(),
      select
    };
    client.get("/env/heater_run_time", { params })
      .then(response => {
        setData(response.data.reduce((accumulator, { heater_name, value}) => {
          accumulator[heater_name] = value;
          return accumulator;
        }, {}));
      })
      .catch(error => console.log(error));
  }, [ houseName, latestTimestamp, daysAgo, select ]);

  useEffect(() => {
    if (!latestTimestamp || !latestTimestamp instanceof Date) return;
    if (daysAgo < 0) return;
    if (!SELECT_OPTIONS.includes(select)) return;
    handleSetData();
    if (!polling) return;
    const pollingInterval = 1000 * Number.parseInt(process.env.REACT_APP_POLLING_INTERVAL);
    const interval = setInterval(handleSetData, pollingInterval);
    return () => {
      clearInterval(interval);
      setData(null);
    };
  }, [ handleSetData, latestTimestamp, daysAgo, select, polling ]);

  return { data };
};
