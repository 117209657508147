import { useEffect, useState, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useGroup } from "../../hooks/use-group";
import houseDisplayOrderApi from "../../api/houseDisplayOrderApi";

import "./HouseDisplayOrder.css";

export default function HouseDisplayOrder() {

  const history = useHistory();
  const { group, initGroup } = useGroup();
  const [houses, setHouses] = useState([]);
  const [dragIndex, setDragIndex] = useState(null);
  const listRef = useRef(null);

  const handleDragEnter = useCallback((index) => {
    if (index === dragIndex) return;
    setHouses((prevHouses) => {
      const newHouses = [...prevHouses];
      const [movedHouse] = newHouses.splice(dragIndex, 1);
      newHouses.splice(index, 0, movedHouse);
      return newHouses;
    });
    setDragIndex(index);
  }, [dragIndex]);

  const handleTouchMove = useCallback((event) => {
    const touchY = event.touches[0].clientY;
    const listItems = Array.from(document.querySelectorAll('.house-display-order-list li'));
    const targetItem = listItems.find(item => {
      const rect = item.getBoundingClientRect();
      return touchY >= rect.top && touchY <= rect.bottom;
    });

    if (targetItem) {
      const targetIndex = listItems.indexOf(targetItem);
      if (targetIndex !== dragIndex) {
        handleDragEnter(targetIndex);
      }
    }

    // Prevent default only if dragging
    if (dragIndex !== null) {
      event.preventDefault();
    }
  }, [dragIndex, handleDragEnter]);

  const handleTouchEnd = useCallback((event) => {
    if (dragIndex !== null) {
      event.preventDefault();
      setDragIndex(null);
    }
  }, [dragIndex]);

  useEffect(() => {
    setHouses(group.houses.map(({ name, displayName }) => ({ name, displayName })));
  }, [group]);

  useEffect(() => {
    const list = listRef.current;

    if (list) {
      list.addEventListener('touchmove', handleTouchMove, { passive: false });
      list.addEventListener('touchend', handleTouchEnd, { passive: false });
    }

    return () => {
      if (list) {
        list.removeEventListener('touchmove', handleTouchMove);
        list.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, [handleTouchMove, handleTouchEnd]);

  const handleDragStart = (event, index) => {
    event.dataTransfer.effectAllowed = "move";
    setDragIndex(index);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const handleDragEnd = () => {
    setDragIndex(null);
  };

  const handleTouchStart = (index) => {
    setDragIndex(index);
  };

  const handleUpdateHouseDisplayOrder = async () => {
    try {
      await houseDisplayOrderApi.update(group.name, houses.map(({ name }, index) => ({
        house_name: name,
        display_order: index
      })));
      await initGroup();
    } catch (error) {
      console.log(error);
    }
    history.push("/login");
  };

  return (
    <div className="house-display-order-container">
      <div className="house-display-order-title">
        <p className="house-display-order-title-text">圃場表示順の設定</p>
      </div>
      <div className="house-display-order-update-button">
        <button onClick={handleUpdateHouseDisplayOrder}>更新</button>
      </div>
      <ul className="house-display-order-list" ref={listRef}>
        {houses.map(({ displayName }, index) => (
          <li
            key={index}
            draggable={true}
            onDragStart={(event) => handleDragStart(event, index)}
            onDragEnter={() => handleDragEnter(index)}
            onDragOver={(event) => handleDragOver(event)}
            onDragEnd={handleDragEnd}
            onTouchStart={() => handleTouchStart(index)}
            className={index === dragIndex ? 'dragging' : ''}
          >
            <div>{displayName}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};
