import MaximizingPhotosyntheticEnvInfo from "./MaximizingPhotosyntheticEnvInfo";
import MaximizingPhotosyntheticPlot from "./MaximizingPhotosyntheticPlot";
import MaximizingPhotosyntheticBarChart from "./MaximizingPhotosyntheticBarChart";

import "./MaximizingPhotosyntheticCO2ConcentrationChange.css";

export default function MaximizingPhotosyntheticCO2ConcentrationChange({ latestCo2Concentration, latestAirTemperature, changedPhotosyntheticRate, realPhotosyntheticRate, simulationCo2Concentration }) {

  const displayValue = (value, fractionDigits) => {
    if (value === null) return "N/A";
    if (typeof fractionDigits === "number") return Number.parseFloat(value).toFixed(fractionDigits);
    return value;
  }

  const displayDifference = (changedValue, realValue) => {
    const diff = (0 < realValue ? -(1 - changedValue / realValue) : (1 - changedValue / realValue)) * 100;
    if (diff === 0) return "";
    if (0 < diff) {
      return (<p>現在値より<br /><span className="co2-concentration-change-difference-up">約 {displayValue(diff, 2)}% 増加</span></p>);
    } else {
      return (<p>現在値より<br /><span className="co2-concentration-change-difference-down">約 {displayValue(diff, 2)}% 減少</span></p>);
    }
  }

  return (
    <div className="co2-concentration-change-container">
      <div className="co2-concentration-change-title">
        <div className="co2-concentration-change-title-text">CO2濃度変更 (CO2濃度 {simulationCo2Concentration} ppm) による効果</div>
      </div>
      <div className="co2-concentration-change-content">
        <div className="co2-concentration-change-value-area">
          <ul className="co2-concentration-change-changed-value">
            <li>
              <MaximizingPhotosyntheticEnvInfo infoName={"co2_concentration"} value={simulationCo2Concentration} label={"変更値"} />
            </li>
            <li>
              <MaximizingPhotosyntheticEnvInfo infoName={"air_temperature"} value={latestAirTemperature} label={"現在値"} />
            </li>
          </ul>
          <div className="co2-concentration-change-arrow-area">
            <div className="co2-concentration-change-arrow-triangle-top"></div>
            <div className="co2-concentration-change-arrow-square"></div>
            <div className="co2-concentration-change-arrow-triangle-bottom"></div>
          </div>
          <ul className="co2-concentration-change-real-value">
            <li>
              <MaximizingPhotosyntheticEnvInfo infoName={"co2_concentration"} value={latestCo2Concentration} label={"現在値"}/>
            </li>
            <li>
              <MaximizingPhotosyntheticEnvInfo infoName={"air_temperature"} value={latestAirTemperature} label={"現在値"} />
            </li>
          </ul>
        </div>
        <div className="co2-concentration-change-plot-area">
          <div className="co2-concentration-change-plot-label-area">
            <div className="co2-concentration-change-plot-label">個葉光合成速度 (μmol/m2/s)</div>
            <div className="co2-concentration-change-plot-value">{displayValue(realPhotosyntheticRate, 2)} (現在値) / {displayValue(changedPhotosyntheticRate, 2)} (変更値)</div>
          </div>
          <div className="co2-concentration-change-plot">
            <MaximizingPhotosyntheticPlot
              dividend={realPhotosyntheticRate}
              divisor={changedPhotosyntheticRate}
            />
          </div>
          <div>
            <MaximizingPhotosyntheticBarChart
              realPhotosyntheticRate={realPhotosyntheticRate}
              changedPhotosyntheticRate={changedPhotosyntheticRate}
            />
          </div>
          <div className="co2-concentration-change-difference">
            {displayDifference(changedPhotosyntheticRate, realPhotosyntheticRate)}
          </div>
        </div>
      </div>
    </div>
  );
}
