import { useEffect, useState } from "react";
import { format } from "date-fns";
import Plot from "react-plotly.js";
import useLatestHeaterRunTime from "../../hooks/use-latest-heater-run-time";
import useHourlyHeaterRunTimePerDay from "../../hooks/use-hourly-heater-run-time-per-day";

import "./HeaterFuelConsumption.css";

export default function HeaterFuelConsumption({ houseName, heaters, plantingDate }) {

  const { timestamp: latestTimestamp } = useLatestHeaterRunTime(houseName, plantingDate);
  const { data: heaterRunTimeData } = useHourlyHeaterRunTimePerDay(houseName, latestTimestamp);

  const [plotData, setPlotData] = useState([]);
  const [plotLayout, setPlotLayout] = useState(null);

  const calculateHeaterFuelConsumption = (heaterRunTimeData, heaters) => {
    return Object.entries(heaterRunTimeData).reduce((accumulator, [ timestamp, data ]) => {
      const totalFuelConsumption = Object.entries(data).reduce((totalFuelConsumption, [ heaterName, value ]) => {
        const heater = heaters.find(({ name }) => name === heaterName);
        totalFuelConsumption += heater.fuelConsumption * value / 60
        return totalFuelConsumption;
      }, null);
      accumulator.push({ timestamp, value: totalFuelConsumption });
      return accumulator;
    }, []);
  };

  const accumulate = (array) => array.reduce((accumulator, currentValue) => {
    const last = accumulator.slice(-1)[0];
    if (typeof last === "number") {
      accumulator.push(currentValue + last);
    } else {
      accumulator.push(currentValue);
    }
    return accumulator;
  }, []);

  const fillDataHourly = array => {
    if (!array || array.length === 0) return [];
    const result = [];
    const lastHour = new Date(array[array.length - 1].timestamp).getHours();
    for (let hour = 0; hour <= lastHour; hour++) {
      const targetData = array.find(data => hour === new Date(data.timestamp).getHours());
      result[hour] = targetData ? targetData.value : 0;
    }
    return result;
  };

  useEffect(() => {

    if (!heaterRunTimeData) return;

    const heaterFuelConsumptionPlotData = fillDataHourly(calculateHeaterFuelConsumption(heaterRunTimeData, heaters));
    const accumulatedHeaterFuelConsumptionPlotData = accumulate(heaterFuelConsumptionPlotData);

    const heaterFuelConsumptionBarChartData = {
      name: "燃料消費量",
      x: [ "0時", "1時", "2時", "3時", "4時", "5時", "6時", "7時", "8時", "9時", "10時", "11時", "12時", "13時", "14時", "15時", "16時", "17時", "18時", "19時", "20時", "21時", "22時", "23時" ],
      y: heaterFuelConsumptionPlotData,
      type: "bar",
      marker: {
        color: "#FF6066"
      },
      hovertemplate: "%{y} L/h",
      yaxis: "y"
    };

    const heaterFuelConsumptionLineChartData = {
      name: "燃料消費量 (積算)",
      x: [ "0時", "1時", "2時", "3時", "4時", "5時", "6時", "7時", "8時", "9時", "10時", "11時", "12時", "13時", "14時", "15時", "16時", "17時", "18時", "19時", "20時", "21時", "22時", "23時" ],
      y: accumulatedHeaterFuelConsumptionPlotData,
      mode: "lines+markers",
      line: {
        shape: "spline",
        color: "#FF1515"
      },
      type: "scatter",
      hovertemplate: "%{y} L",
      yaxis: "y2"
    }

    setPlotData([ heaterFuelConsumptionBarChartData, heaterFuelConsumptionLineChartData ]);

    setPlotLayout({
      barmode: "group",
      bargroupgap: 0.2,
      autosize: true,
      hovermode: "x unified",
      dragmode: false,
      legend: {
        orientation: "h" ,
        x: 0.5,
        y: 1.2,
        xanchor: "center",
        yanchor: "middle"
      },
      yaxis: {
        title: "1時間単位量:棒表示 L"
      },
      yaxis2: {
        title: "日積算量:線表示 L",
        overlaying: 'y',
        side: "right"
      }
    });

    return () => setPlotData([]);

  }, [ heaters, heaterRunTimeData ]);

  const config = {
    displayModeBar: false
  };

  return (
    <div className="heater-fuel-consumption-container">
      <div className="heater-fuel-consumption-title">
        <div className="heater-fuel-consumption-title-text">暖房機燃料消費量</div>
        { latestTimestamp && <span className="heater-fuel-consumption-timestamp">{`${format(latestTimestamp, "yyyy/MM/dd HH:mm")} 時点`}</span> }
      </div>
      <div className="heater-fuel-consumption-content">
        <Plot
          data={plotData}
          layout={plotLayout}
          config={config}
          style={{ width: "100%", height: "100%" }}
          useResizeHandler={true}
        />
      </div>
    </div>
  );
}
