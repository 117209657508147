import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../hooks/use-auth';
import { useGroup } from '../../hooks/use-group';

export default function Login() {

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const handleUserNameChange = event => setUserName(event.target.value);
  const handlePasswordChange = event => setPassword(event.target.value);

  const history = useHistory();
  const { user, login } = useAuth();
  const { group, initGroup } = useGroup();

  const init = useCallback(() => {
    initGroup();
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!user) return;
    init();
  }, [user, init]);

  useEffect(() => {
    if (!user || !group) return;
    history.replace(`/${group.houses[0].name}`);
  }, [user, group, history])

  const handleSubmit = event => {
    event.preventDefault();
    login(userName, password)
  };

  return (
    <div>
      <h1>ログイン</h1>
      <form onSubmit={handleSubmit}>
        <p>
          <label>
            ユーザー名:
            <input type="text" name="userName" value={userName} onChange={handleUserNameChange}></input>
          </label>
        </p>
        <p>
          <label>
            パスワード:
            <input type="password" name="password" value={password} onChange={handlePasswordChange}></input>
          </label>
        </p>
        <p>
          <button type="submit">ログイン</button>
        </p>
      </form>
    </div>
  );
}
