import "./MaximizingPhotosyntheticEnvInfo.css";
import icon_air_temperature from "../../assets/env_info_icon_air_temperature.png";
import icon_co2_concentration from "../../assets/env_info_icon_co2_concentration.png";

const ICONS = {
  air_temperature: icon_air_temperature,
  co2_concentration: icon_co2_concentration,
};

const ENV_INFO_LIST = {
  "air_temperature": { displayName: "温度", unit: "℃" },
  "co2_concentration": { displayName: "CO2濃度", unit: "ppm" },
};

export default function MaximizingPhotosyntheticEnvInfo({ infoName, value, label }) {

  const displayLabel = label ? ` (${label})` : "";
  const displayValue = (value === null) ?  "N/A" : value;

  return (
    <div className="maximizing-photosynthetic-env-info-container">
      <div className="maximizing-photosynthetic-env-info-icon">
        <img src={ICONS[infoName]} alt={infoName} width="100%" />
      </div>
      <div className="maximizing-photosynthetic-env-info-content">
        <div className="maximizing-photosynthetic-env-info-name">{ENV_INFO_LIST[infoName].displayName + displayLabel}</div>
        <div className="maximizing-photosynthetic-env-info-value">
          {displayValue} <span className="maximizing-photosynthetic-env-info-value-unit">{ENV_INFO_LIST[infoName].unit}</span>
        </div>
      </div>
    </div>
  );
};
