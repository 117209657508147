import { useEffect, useState } from "react";
import { format } from "date-fns";
import useLatestEnvInfos from "../../hooks/use-latest-env-infos";
import EnvInfoList from "./EnvInfoList";
import "./EnvInfo.css";
import title_icon_env_info from "../../assets/title_icon_env_info.png";

const TARGETS = [
  "air_temperature",
  "relative_humidity",
  "co2_concentration",
  "solar_irradiance",
  "soil_volumetric_water_content",
  "soil_electrical_conductivity",
  "soil_temperature",
];

export default function EnvInfo({ houseName, plantingDate }) {

  const { value } = useLatestEnvInfos(houseName, TARGETS, plantingDate);
  const [timestamp, setTimestamp] = useState(null);

  useEffect(() => {
    if (!value) return;
    const latestAirTemperature = value["air_temperature"][0];
    if (!latestAirTemperature) return;
    setTimestamp(new Date(latestAirTemperature.timestamp));
    return () => setTimestamp(null);
  }, [value]);

  return (
    <div className="env-info-container">
      <div className="env-info-title">
        <div className="env-info-title-icon">
          <img src={title_icon_env_info} alt="圃場画像" width="100%" />
        </div>
        <div className="env-info-title-text">圃場内環境情報</div>
        { timestamp && <span className="env-info-timestamp">{`${format(timestamp, "yyyy/MM/dd HH:mm")} 時点`}</span> }
      </div>
      <div className="env-info-content">
        <div className="env-info-list-area">
          <EnvInfoList houseName={houseName} envData={value} />
        </div>
      </div>
    </div>
  );
};
