import { Route, Redirect } from 'react-router-dom';

import { useAuth } from '../hooks/use-auth';

export default function PrivateRoute({ children, ...rest }) {
  const auth = useAuth();
  const render = ({ location }) => {
    return auth.user ? children : (
      <Redirect
        to={{ pathname: "/login", state: { from: location } }}
      />
    );
  }
  return <Route {...rest} render={render} />;
}
