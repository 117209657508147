import { useState } from "react";
import { useGroup } from '../../hooks/use-group';
import irrigationSettingApi from "../../api/irrigationSettingApi";

import "./IrrigationIndicatorSettingModal.css";

const MIN_IDEAL_IRRIGATION_COEFFICIENT = 0;
const MAX_IDEAL_IRRIGATION_COEFFICIENT = 99;

const MIN_PLANTING_DENSITY = 0;
const MAX_PLANTING_DENSITY = 9999;

const MIN_SOLAR_IRRADIANCE_PROPORTIONAL_WATER_AMOUNT = 0;
const MAX_SOLAR_IRRADIANCE_PROPORTIONAL_WATER_AMOUNT = 999;

export default function IrrigationIndicatorSettingModal({
  houseName,
  houseDisplayName,
  cropName,
  idealIrrigationCoefficient,
  plantingDensity,
  solarIrradianceProportionalWaterAmount,
  onClose
}) {

  const [newIdealIrrigationCoefficient, setNewIdealIrrigationCoefficient] = useState(idealIrrigationCoefficient);
  const [newPlantingDensity, setNewPlantingDensity] = useState(plantingDensity);
  const [newSolarIrradianceProportionalWaterAmount, setNewSolarIrradianceProportionalWaterAmount] = useState(solarIrradianceProportionalWaterAmount);
  const { initGroup } = useGroup();

  const handleChangeIdealIrrigationCoefficient = (value) => {
    if (value === "") return setNewIdealIrrigationCoefficient("");
    if (isNaN(value)) return setNewIdealIrrigationCoefficient("");
    if (value >= MIN_IDEAL_IRRIGATION_COEFFICIENT && value <= MAX_IDEAL_IRRIGATION_COEFFICIENT) {
      setNewIdealIrrigationCoefficient(value);
    }
  };

  const handleChangePlantingDensity = (value) => {
    if (value === "") return setNewPlantingDensity("");
    if (isNaN(value)) return setNewPlantingDensity("");
    if (value >= MIN_PLANTING_DENSITY && value <= MAX_PLANTING_DENSITY) {
      setNewPlantingDensity(value);
    }
  };

  const handleChangeSolarIrradianceProportionalWaterAmount = (value) => {
    if (value === "") return setNewSolarIrradianceProportionalWaterAmount("");
    if (isNaN(value)) return setNewSolarIrradianceProportionalWaterAmount("");
    if (value >= MIN_SOLAR_IRRADIANCE_PROPORTIONAL_WATER_AMOUNT && value <= MAX_SOLAR_IRRADIANCE_PROPORTIONAL_WATER_AMOUNT) {
      setNewSolarIrradianceProportionalWaterAmount(value);
    }
  };

  const handleUpdateSetting = async () => {
    try {
      if(idealIrrigationCoefficient === undefined) {
        await irrigationSettingApi.create(houseName, cropName, {
          ideal_irrigation_coefficient: newIdealIrrigationCoefficient,
          planting_density: newPlantingDensity,
          solar_irradiance_proportional_water_amount: newSolarIrradianceProportionalWaterAmount,
        });

      } else {
        await irrigationSettingApi.update(houseName, cropName, {
          ideal_irrigation_coefficient: newIdealIrrigationCoefficient,
          planting_density: newPlantingDensity,
          solar_irradiance_proportional_water_amount: newSolarIrradianceProportionalWaterAmount,
        });
      }
      await initGroup();
    } catch (error) {
      console.log(error);
    }
    onClose();
  };

  return (
    <div className="irrigation-indicator-setting-modal-container">
      <div className="irrigation-indicator-setting-modal-title">
        <p className="irrigation-indicator-setting-modal-title-text">かん水指標の設定（{houseDisplayName}）</p>
        <div
          className="irrigation-indicator-setting-modal-close-button"
          onClick={onClose}
        >
          <span>×</span>
        </div>
      </div>
      <div className="irrigation-indicator-setting-modal-table">
        <table>
          <thead>
            <tr>
              <th>項目名</th>
              <th>設定値</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>標準かん水係数</td>
              <td>
                <input
                  type="text"
                  value={newIdealIrrigationCoefficient}
                  onChange={(event) => handleChangeIdealIrrigationCoefficient(event.target.value)}
                /> 倍
              </td>
            </tr>
            <tr>
              <td>栽植密度</td>
              <td>
                <input
                  type="text"
                  value={newPlantingDensity}
                  onChange={(event) => handleChangePlantingDensity(event.target.value)}
                /> 株/m2
              </td>
            </tr>
            <tr>
              <td>日射比例かん水量</td>
              <td>
                <input
                  type="text"
                  value={newSolarIrradianceProportionalWaterAmount}
                  onChange={(event) => handleChangeSolarIrradianceProportionalWaterAmount(event.target.value)}
                /> kg/MJ/株
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="irrigation-indicator-setting-modal-update-button">
        <button onClick={handleUpdateSetting}>更新</button>
      </div>
    </div>
  );
};
