import client from "./client";

const fetch = async (infoName, groupName, houseName, cameraName, cropName, start, end, select = undefined, isCanopy = false) => {
  const url = isCanopy ? `/sp2/canopy/${infoName}` : `/sp2/${infoName}`;
  try {
    const response = await client.get(url, {
      params: {
        group_name: groupName,
        house_name: houseName,
        camera_name: cameraName,
        crop_name: cropName,
        start,
        end,
        select
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const sp2Api = {
  fetch
};

export default sp2Api;