import { useEffect, useState } from "react";
import { isToday } from "date-fns";
import thumbnailApi from "../../api/thumbnailApi";

import "./MonthlyImageCalendarThumbnail.css";

export default function MonthlyImageCalendarThumbnail({ houseName, cameraName, date, onClick }) {

  const [image, setImage] = useState(null);

  useEffect(() => {

    if (!houseName || !cameraName || !date) return;

    (async () => {
      try {
        // 12時台の画像から1枚表示
        const firstNoonImage = await thumbnailApi.fetchFirstInNoon(houseName, cameraName, date);
        if (firstNoonImage) {
          return setImage(firstNoonImage);
        }
        // 12時台の画像が無い場合は9〜15時から最新の画像
        const latestImageFrom9To15 = await thumbnailApi.fetchLatestFrom9To15(houseName, cameraName, date);
        if (latestImageFrom9To15) {
          return setImage(latestImageFrom9To15);
        }
        // 9〜15時の画像が無い場合は最新の画像
        const latestImage = await thumbnailApi.fetchLatestInDay(houseName, cameraName, date);
        if (latestImage) {
          return setImage(latestImage);
        }
      } catch (error) {
        console.log(error);
      }
    })();

    return () => setImage(null);

  }, [houseName, cameraName, date]);;

  const handleClick = () => onClick(date);

  return (
    <>
      {
        image
          ?
            (
              <img
                style={{ cursor: "pointer", border: isToday(date) ? "solid red" : null }}
                src={`data:image/${image.fileType};base64,${image.value}`}
                width="100%" alt={image.timestamp}
                onClick={handleClick}
              />
            )
          : <p
              className="monthly-image-calendar-no-image"
              style={isToday(date) ? { border: "solid red" } : null}
            >
              No Image
            </p>
      }
    </>
  );
}
